import { Action, combineReducers, createStore, Store } from 'redux';
import { getLocale, getVision } from "./helpers";
import { IAvatar, IUser, INotification } from './interfaces';
import RDialogs, {IDialog} from "./components/Chat/reducer";
import { number } from 'yup';

export interface IStore extends Store {
  locale: string;
  vision: boolean;
  user: IUserStore;
  profile: IUser;
  participantsCount: number;
  notifications: INotification[];
  dialogs: IDialog[];
  counts: ICounts;
}

export interface IVisionAction {
  type: string;
  payload: boolean;
}

export const SET_VISION = 'SET_VISION';

export const ASetVision = (vision: boolean): IVisionAction => ({
  type: SET_VISION,
  payload: vision,
});

const RVision = (state: boolean = getVision(), action: IVisionAction) => {
  switch (action.type) {
    case SET_VISION:
      return action.payload;
    default:
      return state;
  }
}

interface ILocaleAction {
  type: string;
  payload: string;
}

export const SET_LOCALE = 'SET_LOCALE';

export const ASetLocale = (locale: string): ILocaleAction => ({
  type: SET_LOCALE,
  payload: locale,
});

const RLocale = (state: string = getLocale(), action: ILocaleAction) => {
  switch (action.type) {
    case SET_LOCALE:
      document.documentElement.lang = action.payload;

      return action.payload;
    default:
      return state;
  }
};

export interface IUserStore extends Partial<IUser> {
  authenticated: boolean;
  id: number;
  filled: boolean;
  blocked: number;
  program_level_hours_remaining: number,
  days_remaining_type: number;
  days_remaining: number;
  profile_filled_at: string | null;
  limited_access: number;
  first_name: string;
  last_name: string;
  user_role_id: number;
  avatar: IAvatar | null;
  area_id: number;
  is_password_updated: boolean;
  is_agree: boolean;
  is_show_hint: boolean;
  email: string;
  new_notification_count: number;
  notification_count: number;
  new_trophies_count: number;
  program_level_id: number;
  winner: any;
}

interface IUserAction {
  type: string;
  payload?: IUserStore;
}

export interface IRefTask {
  refused_task_sent_at: string | undefined
}

export interface IRefAction {
  type: string;
  payload?: string | null;
}

export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_REFUSED_TASK_SEND_AT = 'SET_REFUSED_TASK_SEND_AT';

export const ASetUser = (payload: IUserStore): IUserAction => ({
  type: SET_USER,
  payload
});

export const ASetRefusedTaskSendAt = (payload: IRefTask): IRefAction => ({
  type: SET_REFUSED_TASK_SEND_AT,
  payload: payload.refused_task_sent_at
});

export const AClearUser = (): IUserAction => ({
  type: CLEAR_USER,
})

const initialState: IUserStore = {
  authenticated: false,
  id: 0,
  filled: false,
  blocked: 0,
  days_remaining: 0,
  days_remaining_type: 0,
  limited_access: 0,
  profile_filled_at: null,
  first_name: '',
  last_name: '',
  user_role_id: 0,
  program_level_hours_remaining: 0,
  avatar: null,
  area_id: 0,
  is_password_updated: false,
  is_agree: false,
  is_show_hint: false,
  email: '',
  new_notification_count: 0,
  notification_count: 0,
  new_trophies_count:0,
  program_level_id: 0,
  winner: null
};

const RUser = (state = initialState, action: IUserAction) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.payload };
    case CLEAR_USER:
      return { ...initialState };
    default:
      return state;
  }
};

interface ICounts {
  centers: number;
  coordinators: number;
  experts: number;
  members: number;
  mentors: number;
  providers: number;
  refused_task_sent_at?: string | undefined;
}

interface ICountsAction extends Action {
  payload: ICounts;
}

const SET_COUNTS = 'SET_COUNTS';

export const ASetCounts = (counts: ICounts): ICountsAction => ({
  type: SET_COUNTS,
  payload: counts,
});


const RCounts = (state: ICounts = {
  centers: 0,
  coordinators: 0,
  experts: 0,
  members: 0,
  mentors: 0,
  providers: 0,
  refused_task_sent_at: undefined,
}, action: ICountsAction) => {
  if (action.type === SET_COUNTS) {
    return action.payload;
  }
  if (action.type === SET_REFUSED_TASK_SEND_AT) {
    return { ...state, refused_task_sent_at: action.payload };
  }

  return state;
};

interface IParticipantsCount extends Action {
  payload: number;
}

const SET_PARTICIPANTS_COUNT = 'SET_PARTICIPANTS_COUNT';

export const ASetParticipantsCount = (count: number): IParticipantsCount => ({
  type: SET_PARTICIPANTS_COUNT,
  payload: count,
});


const RParticipantsCount = (state: number = 0, action: IParticipantsCount) => {
  if (action.type === SET_PARTICIPANTS_COUNT) {
    return action.payload;
  }

  return state;
};

interface INotificationAction extends Action {
  type: string;
  payload: INotification[];
}

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const ASetNotifications = (notifications: INotification[]): INotificationAction => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});


const RNotifications = (state: INotification[] = [], action: INotificationAction) => {
  if (action.type === SET_NOTIFICATIONS) {
    return [...action.payload];
  }

  return state;
};


const reducers = combineReducers({
  vision: RVision,
  locale: RLocale,
  user: RUser,
  participantsCount: RParticipantsCount,
  notifications: RNotifications,
  dialogs: RDialogs,
  counts: RCounts,
});

const store: IStore = createStore(reducers);

export default store;
