import React from 'react';
import qs from "qs";
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import LoadablePage from "../../components/LoadablePage";
import {IStore} from "../../store";
import { getFaqCategories } from '../Cabinet/pages/FAQ/api';
import {getFaqDetails} from "./api";

const PageFaq: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const params: {
    category_id?: number,
  } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      data: getFaqDetails,
      categories: getFaqCategories,
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      const category_id  = loaded.categories.length !== 0 ? loaded.categories[0].id:null;
     //@ts-ignore
      if (category_id) {
        //@ts-ignore
        params.category_id = category_id;
      }
      //setParams(params);
      return (
        <Page
          {...loadedProps}
          locale={locale}
          categories={loaded.categories}
          //@ts-ignore
          data={category_id? loaded.data.filter(item=>item.category_id===category_id): loaded.data}
 />
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageFaq;
