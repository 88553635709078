import React from 'react';

interface IProps {
  senderName: string;
  message: string
}

const MessageText: React.FC<IProps> = (props) => {
  const {senderName, message} = props;

  return (
    <div className="text">
      <div className="sender-name">
        {senderName}
      </div>
      {message}
    </div>
  );
};

export default MessageText;
