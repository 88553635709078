import instance from "../../../../api";
import {IMentor, IUser} from "../../../../interfaces";

export interface Certificates {
  type_id: number;
  type: string;
  program_level_id: number;
  year: number;
}

export interface IProfileInfo extends IUser {
  mentor: string | null;
  certificates?: Certificates[]
}

export interface IVideos
{
  id: number;
  name: string;
  path: string;
  user_id: number;
  url: string;
  status_id: number;
  fileable_type: string;
  fileable_id: number;
  type_id: number;

}

export interface IImages
{
  id: number;
  name: string;
  thumb: string;
  path: string;
  imageable_type: string;
  imageable_id: number;
  status_id: number;
}

export interface IMemberReport {
  id: number;
  comment: string;
  videos: IVideos[];
  images: IImages[];
  doc_comments:[];
  certificates:[];
}

export const getProfileInfo = (slug: string, id: string): Promise<IProfileInfo> => {
  return instance().get(`/v1/${slug}/members/${id}`).then(response => response.data.data.user);
};

export const getProfileInfoFull = (slug: string, id: string): Promise<{
  mentor: IMentor,
  user: IProfileInfo
}> => {
  return instance().get(`/v1/${slug}/members/${id}`).then(response => response.data.data);
};

export const postDeclineTask = (data: any, id:string, slug: string = 'mentor') => {
  return instance().post(`/v1/${slug}/tasks/${id}/actions`, data).then(response => response.data.data);
};

export const postMoveTask = (data: any, id:string) => {
  return instance().post(`/v1/mentor/tasks/${id}/move`, data).then(response => response.data.data);
};

export const postMoveTaskWithSlug = (data: any, id:string, slug: string) => {
  return instance().post(`/v1/${slug}/tasks/${id}/move`, data).then(response => response.data.data);
};

export const getMemberTask = (member_id:number, task_id: string) => {
  return instance().get(`/v1/mentor/members/${member_id}/tasks/${task_id}`).then(response => response.data.data);
}

export const getMemberProgress = (member_id:number, slug = 'mentor', program_level_id = 1, start_date_from?: string, start_date_to?: string) => {
  return instance().get(`/v1/${slug}/members/${member_id}/statistics`, {
    params: {
      program_level_id,
      start_date_from,
      start_date_to
    }
  }).then(response => response.data);
}


