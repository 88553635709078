import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import LoadablePage from "../../components/LoadablePage";
import {capitalize, createArrayOfNumbers, monthName} from "../../helpers";
import {IStore} from "../../store";
import {
    getCountries,
    getGenders,
    getProgramLevels,
    getAreas,
    getCities,
    getEducationOrganizations,
    getEducationCategories,
    getDirections, getEducationCourses, getNationalities
} from './api';

const PageRequest: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      countries: getCountries,
      areas: () => getAreas(1),
      cities: getCities,
      genders: getGenders,
      nationalities: getNationalities,
      programLevels: getProgramLevels,
      educationCategories: getEducationCategories,
      educationOrganizations: getEducationOrganizations,
      directions: getDirections,
      educationCourses: getEducationCourses,
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps}
          days={createArrayOfNumbers(1, 32).map(item => ({
            label: item < 10 ? `0${item}` : item.toString(),
            value: item,
          }))}
          months={createArrayOfNumbers(1, 13).map(item => ({
            value: item,
            label: capitalize(monthName(item - 1, locale)),
          }))}
          years={createArrayOfNumbers(1990, 2021).map(item => ({
            label: item.toString(),
            value: item,
          }))}
          countries={loaded.countries.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id
          }))}
          areas={loaded.areas.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id
          }))}
          nationalities={loaded.nationalities.data.map((item: any) => ({
              // @ts-ignore
              label: item[`title_${locale}`],
              value: item.id
          }))}
          cities={loaded.cities.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
            ...item,
          }))}
          educationCategories={loaded.educationCategories.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
          }))}
          educationOrganizations={loaded.educationOrganizations.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
          }))}
          educationCourses={loaded.educationCourses.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
          }))}
          genders={loaded.genders.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
          }))}
          programLevels={loaded.programLevels.map(item => ({
            // @ts-ignore
            label: item[`title_${locale}`],
            value: item.id,
          }))}/>
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageRequest;
