import React, { useState, useEffect } from 'react';

interface IInputProps {
  textarea?: boolean,
  mask?: string;
  error?: string | null;
  label: string;
  required?: boolean;
  value: string | number;
  name: string;
  autocomplete?: string;
  type: string;
  extraError?: boolean;
  extraErrorText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  disabled?: boolean,
}

const Input = React.forwardRef((props: IInputProps, ref) => {
  const { label, required = false, error = null, extraError = null, autocomplete, extraErrorText, mask, textarea = false, disabled = false, type: inputType, onKeyPress, ...rest } = props;
  const [type, setType] = useState(inputType);
  const inputProps = {};

  useEffect(() => {
    // @ts-ignore
    autosize($('textarea.form-control'));

  }, []);

  if (!!mask) {
    // @ts-ignore
    inputProps['data-mask'] = mask;
  }

  const showPassword = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <div>
      <div className="form-group combined" style={{
        border: error || extraError ? '1px solid #e54e59' : 'none',
        backgroundColor: disabled ? '#eee' : '#FFF'
      }}>
        <label className="control-label" style={!!rest.value ? {
          display: 'block',
          opacity: 1,
        } : {}}>
          {label}
          {required ? <span className="red">*</span> : null}
        </label>
        {textarea ? (
          <textarea
            {...rest}
            {...inputProps}
            disabled={disabled}
            className="form-control"
            style={{
              backgroundColor: disabled ? '#eee' : '#FFF'
            }}
            placeholder={`${label}${required ? ' *' : ''}`}
            rows={10}
            cols={6} />
        ) : (
            <input
              {...rest}
              {...inputProps}
              disabled={disabled}
              autoComplete="piece"
              // @ts-ignore
              ref={ref}
              onKeyPress={onKeyPress}
              type={type}
              style={{
                backgroundColor: disabled ? '#eee' : '#FFF',
              }}
              className="form-control"
              placeholder={`${label}${required ? ' *' : ''}`} />
          )}
        {inputType === 'password' && (
          <a className="show-pass" onClick={showPassword} />
        )}
      </div>
      {error || extraError ? (
        <span style={{
          color: '#e54e59',
          marginTop: -12,
          display: 'block',
          fontSize: 12,
          marginLeft: 16,
          marginBottom: 20,
        }}>
          {error ? error : extraErrorText}
        </span>
      )
    : null
    }
    </div>
  );
});

export default Input;
