import instance from "../../../../api";
import { ICategory } from "../ProfileSchedule/api";

export interface IResponseVideo {
  id: number;
  title_ru: string;
  title_kz: string;
  description_ru: string;
  description_kz: string;
  link: string;
  category_id: number;
  direction_id: number;
  type: number;
  status_id: number;
  created_at: string;
  updated_at: string;
  category: {
    id: number;
    title_ru: string;
    title_kz: string;
    status_id: number;
  },
  direction: {
    id: number;
    title_ru: string;
    title_kz: string;
    status_id: number;
    created_at: string;
    updated_at: string;
  },
  video_code: string;
}

export interface IResponseFaq {
  id: number;
  title_ru: string;
  title_kz: string;
  answer_ru: string;
  answer_kz: string;
  answer_no_count: number;
  answer_yes_count: number;
  category_id: number;
  direction_id: number;
  type: number;
  status_id: number;
  created_at: string;
  updated_at: string;
  user_role_id: number;
  category: {
    id: number;
    title_ru: string;
    title_kz: string;
    status_id: number;
  },
  direction: {
    id: number;
    title_ru: string;
    title_kz: string;
    status_id: number;
    created_at: string;
    updated_at: string;
  }
}

export interface IResponse {
  data: IResponseVideo[];
  meta: {
    current_page: number;
    last_page: number;
  }
}

export const getVideos = (params: {
  locale: string,
  category_id?: number,
  direction_id?: number,
  q?: string,
  is_hint?: number,
}): Promise<IResponse> => {
  if (!params.q) {
    delete params.q;
  }
  // @ts-ignore
  delete params.content;
  return instance().get(`/v1/videos-instruction`, {
    params,
  }).then(response => response.data);
}

export const getVideoCategories = (): Promise<ICategory[]> => {
  return instance().get(`/v1/categories-video/1/categories`).then(response => response.data.data);
};

export const getFaqCategories = (): Promise<ICategory[]> => {
  return instance().get(`/v1/categories-faq`).then(response => response.data.data);
};

export const getFaqDetails = (params: {
  locale: string,
  category_id?: number,
  direction_id?: number,
  q?: string
}) => {
  if (!params.q) {
    delete params.q;
  }
  // @ts-ignore
  delete params.content;
  return instance().get('/v1/faq',{params})
    .then(response => response.data.data);
};

export const postFaqAnswer = (params: {
  answer_type: number,
  faq_id: number,
}) => {
  return instance().post('/v1/faq-answer',params)
    .then(response => response.data.data);
};