import instance from "../../api";
import { INews } from "../../interfaces";

export interface INewsFilterParams {
  page: number;
  sort_by?: string;
  q?: string;
  date_from?: string;
  date_to?: string;
}

interface IGetNewsParams extends INewsFilterParams {
  locale: string;
}

export const getNews = (params: IGetNewsParams): Promise<{
  meta: {
    total: number;
    per_page: number;
  },
  data: INews[]
}> => {
  return instance(false).get('/v1/news',{
    params,
  }).then(response => response.data);
}
