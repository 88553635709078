import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { match as IRRDMatch, Redirect, useRouteMatch } from 'react-router-dom';
import { getContent } from '../../../api';
import LoadablePage from '../../../components/LoadablePage';
import { PATH } from '../../../router';
import { IStore } from '../../../store';

interface IMatch extends IRRDMatch {
  params: {
    type?: string;
  };
}

const PageLevels: React.FC = (props) => {
  const match: IMatch = useRouteMatch();
  const locale = useSelector((state: IStore) => state.locale);
  const {type} = match.params;

  if (!type || type === ':type') {
    return <Redirect to={PATH.generate(PATH.levels, 'tab-bronze')} />;
  }

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      sectionBronze: () => import(`./sections/BronzeSection`),
      sectionSilver: () => import(`./sections/SilverSection`),
      sectionGold: () => import(`./sections/GoldSection`),
      bronze_block: () => getContent(`bronze-medal-block`),
      silver_block: () => getContent(`silver-medal-block`),
      gold_block: () => getContent(`gold-medal-block`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const sectionBronze: any = loaded.sectionBronze.default;
      const sectionSilver: any = loaded.sectionSilver.default;
      const sectionGold: any = loaded.sectionGold.default;
      const textBronze: any = loaded.bronze_block;
      const textSilver: any = loaded.silver_block;
      const textGold: any = loaded.gold_block;

      console.log('block', textBronze);

      return (
        <Page
          {...loadedProps}
          tab={type}
          sectionBronze={sectionBronze}
          sectionSilver={sectionSilver}
          sectionGold={sectionGold}
          textBronze={textBronze}
          textSilver={textSilver}
          textGold={textGold}
          locale={locale}
        />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageLevels;
