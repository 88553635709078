import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IChatSettings, saveChatSettings } from './api';
import classNames from 'classnames';

const ChatPermissions: React.FC<{
  id: string;
  settings: IChatSettings
}> = ({id, ...props}) => {
  const timeOut = useRef<any>(null);
  const [settings, setSettings] = useState<IChatSettings>({...props.settings});
  const {t} = useTranslation();
  const timeLimits: number[] = [0, 5, 10, 15, 20, 25, 30];

  useEffect(() => {
    if (timeOut.current !== null) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      saveSettings();
    }, 1000);
  }, [settings]);

  const saveSettings = () => {
    saveChatSettings(id, settings)
      .then(response => {
      })
      .catch(error => {
      });
  };

  const onChangeSendMessage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    setSettings({
      ...settings,
      send_message: !settings.send_message
    });
  };

  const onChangeAttachPhotoVideo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    setSettings({
      ...settings,
      attach_photo_video: !settings.attach_photo_video
    });
  };

  const onChangeAttachFiles = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    setSettings({
      ...settings,
      attach_files: !settings.attach_files
    });
  };

  const onChangeTimeOut = (time: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    setSettings({
      ...settings,
      timeout_of_sending: time
    });
  };

  return (
    <div className="more-permissions-list">
      <div className="bd-blk">
        <div className="title">{t('permissions')}</div>
        <div className="row e">
          <div className="col-sm-8">{t('sending_message')}</div>
          <div className="col-sm-4">
            <a onClick={onChangeSendMessage} className={classNames("checker-button", {
              "checked": settings.send_message
            })} />
          </div>
        </div>
        <div className="row e">
          <div className="col-sm-8">{t('sending_photo_video')}</div>
          <div className="col-sm-4">
            <a onClick={onChangeAttachPhotoVideo} className={classNames("checker-button", {
              "checked": settings.attach_photo_video
            })} />
          </div>
        </div>
        <div className="row e">
          <div className="col-sm-8">{t('sending_files')}</div>
          <div className="col-sm-4">
            <a onClick={onChangeAttachFiles} className={classNames("checker-button", {
              "checked": settings.attach_files
            })} />
          </div>
        </div>
        <div className="row e">
          <div className="col-sm-8">{t('time_between_send')}</div>
          <div className="col-sm-4">
            <div className="dropdown show">
              <a className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {settings.timeout_of_sending}
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {timeLimits.filter(item => item !== parseInt(settings.timeout_of_sending)).map((item, itemIndex) => (
                  <a onClick={onChangeTimeOut(item.toString())} className="dropdown-item" href="#" key={itemIndex.toString()}>
                    {item.toString()}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPermissions;
