import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getChatAvatar, getRandomColor, getShortName } from '../../helpers';
import { IChatMember } from './Chat';
import { CONSTANTS, IChatUserInfo, socket } from './index';
import { IDialog } from './reducer';

const GroupAddForm: React.FC<{
  members: IChatMember[],
  dialog: IDialog;
  closeGroupAddForm: () => void;
  onlineUsers: number[];
  availableUsers: IChatUserInfo[];
  onAddedToGroup: () => void;
}> = (props) => {
  const {t} = useTranslation();
  const {closeGroupAddForm, dialog, onlineUsers, members} = props;
  const [userIds, setUserIds] = React.useState<number[]>([...members.filter(member => member.user_id !== dialog.user_id).map(member => member.user_id)]);

  const onClickClose = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    closeGroupAddForm();
  };

  const onChangeCheckbox = (userId: number) => (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    if (userIds.indexOf(userId) > -1) {
      setUserIds(userIds.filter(id => id !== userId));
    } else {
      setUserIds([...userIds, userId]);
    }
  };

  const onClickAddToGroup = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (window.confirm(t("delete_user_from_chat_confirm"))) {
      socket.emit(CONSTANTS.ADD_TO_GROUP, {
        chat_id: props.dialog.chat_id,
        users:  [...userIds]
      });

      props.availableUsers.filter((member:any) => member.user_id !== dialog.user_id)
        .forEach(user => {
          if (userIds.indexOf(user.user_id) === -1) {
            socket.emit(CONSTANTS.DELETE_FROM_GROUP, {
              chat_id: props.dialog.chat_id,
              user_id: user.user_id
            });
          }
        });
    }
  };

  return (
    <div className="add-group-blk" style={{display: 'block'}}>
      <div className="head-blk">
        <a className="close-add-group" onClick={onClickClose} />
        <div className={classNames('photo', {
          "no-avatar": !dialog.photo
        })} style={{
          backgroundColor: getRandomColor(dialog.title),
          backgroundImage: getChatAvatar(dialog.photo) || ''
        }}>
          {!dialog.photo && getShortName(dialog.title)}
        </div>
        {dialog.title}
      </div>
      <div className="list-members-blk" style={{height: 320}}>
        <div className="title-block">{t('chat_members')}</div>
        {props.availableUsers.filter((member:any) => member.user_id !== dialog.user_id).map((availableUser: any, availableUserIndex: number) => (
          <div
            key={availableUserIndex.toString()}
            className="members-elm">
            <div className={classNames('photo', {
              'online': onlineUsers.indexOf(availableUser.user_id) > -1,
              "no-avatar": !availableUser.avatar
            })} style={{
              backgroundColor: getRandomColor(availableUser.username),
              backgroundImage: getChatAvatar(availableUser.avatar!) || ''
            }}>
              {!availableUser.avatar && getShortName(availableUser.username)}
            </div>
            <div className="name">{availableUser.username}</div>
            <div className="form-simple">
              <label className="checkcont">
                <input
                  type="checkbox"
                  checked={userIds.indexOf(availableUser.user_id) > -1}
                  onChange={onChangeCheckbox(availableUser.user_id)} />
                <span className="checkmark" />
              </label>
            </div>
          </div>
        ))}
      </div>
      {members.filter(member => member.user_id !== dialog.user_id).length !== userIds.length && (
        <div className="buttons-group-blk">
          <a className="btn btn-primary" onClick={onClickAddToGroup}>{t('add_to_group')}</a>
        </div>
      )}
    </div>
  );
};

export default GroupAddForm;
