import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SOCKET_URL } from '../../api';
import {
  clearPhoneNumber,
  downloadFile, getChatAvatar,
  getRandomColor,
  getShortName,
  getUserThumb,
  maskNumberString,
  MESSAGE_ATTACHMENTS, USER_ROLES
} from '../../helpers';
import { IStore } from '../../store';
import { getDialogMedia, getDialogUserInfo, IChatAttachment, IChatSettings } from './api';
import { IChatMember } from './Chat';
import ChatPermissions from './ChatPermissions';
import { IChatUserInfo } from './index';
import { IDialog } from './reducer';

const ChatProfile: React.FC<{
  onClickAddToGroup: () => void,
  members: IChatMember[],
  isOnline: boolean;
  availableUsers: IChatUserInfo[];
  dialog: IDialog;
  closeProfile: () => void;
  searchText: string;
  settings: IChatSettings;
  hasPermission: boolean;
  onSearch: (searchText: string) => void;
}> = (props) => {
  const {t} = useTranslation();
  const {currentUser} = useSelector((store: IStore) => ({
    currentUser: store.user,
    locale: store.locale
  }));
  const {dialog, closeProfile, isOnline, members, onClickAddToGroup} = props;
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [docs, setDocs] = React.useState<IChatAttachment[]>([]);
  const [media, setMedia] = React.useState<IChatAttachment[]>([]);
  const [showSearch, setShowSearch] = React.useState<boolean>(!!props.searchText);
  const [searchText, setSearchText] = React.useState<string>(props.searchText);
  const [info, setInfo] = React.useState<{
    username: string;
    email: string;
    phone: string;
    members: {
      user_id: number;
      username: string;
    }[]
  }>({
    username: '',
    email: '',
    phone: '',
    members: []
  });

  useEffect(() => {
    let userId: number = dialog.type === 1 ? dialog.to_user_id : dialog.user_id;

    getDialogUserInfo(userId)
      .then(response => {
        setInfo(response);
      })
      .catch(() => {

      });

    getDialogMedia(dialog.chat_id.toString())
      .then(response => {
        if (!!response) {
          setDocs(response.filter(attachment => attachment.type === MESSAGE_ATTACHMENTS.DOC));
          setMedia(response.filter(attachment => attachment.type !== MESSAGE_ATTACHMENTS.DOC));
        }
      })
      .catch(() => {

      });
  }, []);

  const onClickSearch = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    setShowSearch(true);
  };

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    setSearchText(e.target.value);
  };

  const onKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.persist();

    if (e.which === 13 || e.keyCode === 13) {
      props.onSearch(searchText);
    }
  };

  const onClickCloseProfile = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    closeProfile();
  };

  const onClickTabIndex = (index: number) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    setTabIndex(index);
  };

  const onClickAdd = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    onClickAddToGroup();
  };

  const onClickDownloadFile = (attachment: IChatAttachment) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    downloadFile(`${SOCKET_URL}/${attachment.path}/${attachment.filename}`, attachment.filename);
  };

  return (
    <div
      className="chat-info-blk"
      style={{
        display: 'block'
      }}>
      <div className="head-info-blk">
        <a
          className="close-info-link"
          onClick={onClickCloseProfile} />
        {!showSearch && (
          <div className="name">{t('info')}</div>
        )}
        <a className="search-link" onClick={onClickSearch} />
        {showSearch && (
          <div className="search-input" style={{
            display: 'inline-block'
          }}>
            <input
              onChange={onChangeSearchText}
              onKeyPress={onKeyPressSearch}
              value={searchText}
              type="text"
              placeholder={t('search_in_messages')}
              className="form-control" />
          </div>
        )}
      </div>
      <div className="list-info-blk" style={{
        height: 460
      }}>
        <div className="about-chat info-blk">
          <div className="about-blk">
            {/*<div className="photo" style={{backgroundImage: "url(images/avatar_img_1.png)"}}/>*/}
            <div className={classNames('photo', {
              "no-avatar": !dialog.photo
            })} style={{
              backgroundColor: getRandomColor(dialog.title),
              backgroundImage: getChatAvatar(dialog.photo) || ''
            }}>
              {!dialog.photo && getShortName(dialog.title)}
            </div>
            <div className="name">{dialog.title}</div>
            {dialog.type === 1 && (
              <div className={classNames('status-online', {
                'online': isOnline
              })}>{isOnline ? t('online_now') : t('offline_now')}</div>
            )}
            <div className="links-blk">
              <div className="row">
                <div className="col-sm-4">
                  <a
                    className="write"
                    onClick={onClickCloseProfile}>
                    <div className="img" />
                    {t('write_message')}
                  </a>
                </div>
                {currentUser.user_role_id !== USER_ROLES.member.id && (
                  <div className="col-sm-4">
                    <a className="add create-group-link" onClick={onClickAdd}>
                      <div className="img" />
                      {t('add_to_group')}
                    </a>
                  </div>
                )}
                {props.hasPermission && (
                  <div className="col-sm-4">
                    <a className="addition">
                      <div className="img" />
                      {t('more_v2')}
                    </a>
                    <ChatPermissions
                      id={dialog.chat_id}
                      settings={props.settings} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {(!!info.email || !!info.phone) && (
          <div className="admin-about info-blk">
            {!!info.phone && (
              <div className="phone elm">
                <a href={`tel:${maskNumberString(clearPhoneNumber(info.phone), '+7 ### ### ## ##')}`}>
                  {maskNumberString(clearPhoneNumber(info.phone), '+7 ### ### ## ##')}
                </a>
              </div>
            )}
            {!!info.email && (
              <div className="email elm">
                <a href={`mailto:${info.email}`}>
                  {info.email}
                </a>
              </div>
            )}
          </div>
        )}
        <div className="info-blk contents-blk" id="chat-tabs-member">
          <div className="tabs">
            <a className={classNames({'active': tabIndex === 0})} onClick={onClickTabIndex(0)}>
              {t('chat_members')}
            </a>
            <a className={classNames({'active': tabIndex === 1})} onClick={onClickTabIndex(1)}>
              {t('media')}
            </a>
            <a className={classNames({'active': tabIndex === 2})} onClick={onClickTabIndex(2)}>
              {t('files')}
            </a>
            {/*<a href="#" class="">Ссылки</a>*/}
          </div>
          <div className={classNames('tab list-members-blk', {
            'active': tabIndex === 0
          })}>
            <div className="member-elm">
              {/*<div className="photo" style={{backgroundImage: "url(images/avatar_img_2.png)"}} />*/}
              <div className={classNames('photo', {
                      "no-avatar": !currentUser.avatar
                    })}
                   style={{
                     backgroundColor: getRandomColor(`${currentUser.last_name} ${currentUser.first_name}`),
                     backgroundImage: getUserThumb(currentUser.avatar, true, true) || ''
                   }}>
                {!currentUser.avatar && getShortName(`${currentUser.last_name} ${currentUser.first_name}`)}
              </div>
              <div className="name">{t('you')}</div>
            </div>
            {members.filter(member => member.user_id !== currentUser.id).map((member, memberIndex) => (
              <div className="member-elm" key={memberIndex.toString()}>
                <div className={classNames('photo', {
                  "no-avatar": !member.avatar
                })} style={{
                  backgroundColor: getRandomColor(member.username),
                  backgroundImage: getChatAvatar(member.avatar) || ''
                }}>
                  {!member.avatar && getShortName(member.username)}
                </div>
                {/*<div className="photo" style={{backgroundImage: "url(images/avatar_img_2.png)"}} />*/}
                <div className="name">{member.username}</div>
              </div>
            ))}
          </div>
          <div className={classNames('tab list-medias-blk', {
            'active': tabIndex === 1
          })}>
            <div className="row">
              {media.map((mediaItem, mediaIndex: number) => {
                if (mediaItem.type === MESSAGE_ATTACHMENTS.VIDEO) {
                  return null;
                }

                return (
                  <div className="col-sm-3" key={mediaIndex.toString()}>
                    <a className="img"
                       href={`${SOCKET_URL}/${mediaItem.path}/${mediaItem.filename}`}
                       target="_blank"
                       style={{backgroundImage: `url(${SOCKET_URL}/${mediaItem.path}/${mediaItem.filename})`}} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classNames('tab list-files-blk', {
            'active': tabIndex === 2
          })}>
            {docs.map((doc, docIndex: number) => (
              <div className="file-elm" key={docIndex.toString()}>
                <div className="icon" />
                <div className="desc">
                  <a href="#" className="name">{doc.filename}</a>
                  <div className="info">
                    <a href="#" className="chat-download-link" onClick={onClickDownloadFile(doc)} />
                    {/*<span>Нет данных о размере</span> | <span>Нет данных о дате</span>*/}
                    <a href="#" onClick={onClickDownloadFile(doc)}><span>{t('download')}</span></a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatProfile;
