import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getChatAvatar, getRandomColor, getShortName } from '../../helpers';
import { createGroupDialog, saveAvatar } from './api';
import { CONSTANTS, IChatUserInfo, socket } from './index';
import { IDialog } from './reducer';

const ChatGroupForm: React.FC<{
  onDialogCreated: (dialog: IDialog) => void;
  closeCreateGroup: () => void;
  onlineUsers: number[];
  chatGroupAddId: number;
  availableUsers: IChatUserInfo[];
}> = (props) => {
  const {t} = useTranslation();
  const {onlineUsers, availableUsers, closeCreateGroup, onDialogCreated, chatGroupAddId} = props;
  const [avatar, setAvatar] = React.useState<File | null>(null);
  const [userIds, setUserIds] = React.useState<number[]>([chatGroupAddId]);
  const [groupName, setGroupName] = React.useState<string>('');
  const [groupDescription, setGroupDescription] = React.useState<string>('');

  const onChangeGroupName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    setGroupName(e.target.value);
  };

  const onChangeGroupDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    setGroupDescription(e.target.value);
  };

  const onChangeCheckbox = (userId: number) => (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    if (userIds.indexOf(userId) > -1) {
      setUserIds(userIds.filter(id => id !== userId));
    } else {
      setUserIds([...userIds, userId]);
    }
  };

  const onClickCloseCreateGroup = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    closeCreateGroup();
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    setAvatar(e.target.files![0]);
  }

  const onClickCreateGroup = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (groupName.length === 0) {
      return alert(t('enter_group_name'));
    }

    createGroupDialog({
      users: userIds,
      title: groupName,
      avatar,
      description: groupDescription
    })
      .then(({dialog}) => {
        socket.emit(CONSTANTS.JOIN_CHAT, {
          chat_id: dialog.chat_id
        });

        onDialogCreated(dialog);
        // saveAvatar(dialog.chat_id, avatar!)
        //   .then(() => {
        //     onDialogCreated(dialog);
        //   })
        //   .catch(() => {
        //     onDialogCreated(dialog);
        //   });
      })
      .catch(() => {
        window.location.reload()
        // alert(t('error_default_message'));
      });
  };

  return (
    <div
      className="create-group-blk"
      style={{
        display: 'block'
      }}>
      <div className="head-blk">
        <div className="title-block">{t('create_group')}</div>
        <a
          className="close-create-group"
          onClick={onClickCloseCreateGroup} />
        <div className="search-form-blk">
          <div className="row">
            <div className="col-sm-3 label">
              <label className="upload-avatar-group" style={{
                backgroundImage: avatar ? `url(${URL.createObjectURL(avatar)})` : 'url(../images/user_icon.svg)',
                backgroundSize: avatar ? 'cover': '24px 24px'
              }}>
                <input type="file" onChange={onChangeFile} style={{display: 'none'}} />
              </label>
            </div>
            <div className="col-sm-9">
              <div className="form-group">
                <input
                  value={groupName}
                  onChange={onChangeGroupName}
                  type="text"
                  className="form-control"
                  placeholder={t('name_the_group')} />
              </div>
            </div>
            <div className="col-sm-12">
              <input
                value={groupDescription}
                onChange={onChangeGroupDescription}
                type="text"
                className="form-control"
                placeholder={t('description')} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="list-members-blk"
        style={{height: 320}}>
        <div className="title-block">{t('chat_members')}</div>
        {availableUsers.map((availableUser, availableUserIndex) => (
          <div
            key={availableUserIndex.toString()}
            className="members-elm">
            <div className={classNames('photo', {
              'online': onlineUsers.indexOf(availableUser.user_id) > -1,
              "no-avatar": !availableUser.avatar
            })} style={{
              backgroundColor: getRandomColor(availableUser.username),
              backgroundImage: getChatAvatar(availableUser.avatar!) || ''
            }}>
              {!availableUser.avatar && getShortName(availableUser.username)}
            </div>
            <div className="name">{availableUser.username}</div>
            <div className="form-simple">
              <label className="checkcont">
                <input
                  type="checkbox"
                  checked={userIds.indexOf(availableUser.user_id) > -1}
                  onChange={onChangeCheckbox(availableUser.user_id)} />
                <span className="checkmark" />
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="buttons-group-blk">
        <a
          className="btn btn-primary"
          onClick={onClickCreateGroup}>{t('create_group')}</a>
      </div>
    </div>
  );
};

export default ChatGroupForm;
