import instance, {socketInstance, SOCKET_API_URL} from "../../api";
import { IAvatar } from '../../interfaces';
import {IChatUserInfo} from "./index";
import {IDialog} from "./reducer";

export const getChatDialogs = () => {
  return socketInstance()
    .get('/v1/dialogs')
    .then(response => response.data);
};

export interface IChatAttachment {
  file: any;
  filename: string;
  is_uploaded: boolean;
  mimetype: string;
  path: string;
  type: number;
  __v: number;
  _id: string;
}

export interface IChatDialogMessageStatus {
  _id: string;
  read: string | null;
  delivered: string | null;
  chat_id: string;
  user_id: number;
}

export interface IChatDialogMessageReponse {
  attachments: IChatAttachment[];
  chat_id: string;
  created_at: string;
  from_id: number;
  message: string;
  status_id: number;
  to_id: number;
  type: number;
  updated_at: string;
  statuses: IChatDialogMessageStatus[],
  __v: number;
  _id: string;
}

export interface IChatSettings {
  attach_files: boolean;
  attach_photo_video: boolean;
  send_message: boolean;
  timeout_of_sending: string;
  _id: string;
}

export const getChatDialog = (id: string): Promise<{
  messages: IChatDialogMessageReponse[]
}> => {
  return instance(true, SOCKET_API_URL)
    .get(`/v1/dialogs/${id}`)
    .then(response => response.data);
}

export const getDialogMessages = (id: string, params: {
  page?: number;
  limit?: number;
  per_page?: number;
  q?: string;
} = {}): Promise<{
  chat: {
    members: {
      avatar: IAvatar;
      email: string;
      phone: string;
      role: {
        _id: string;
        id: number;
        title: string;
      };
      id: number;
      title: string;
      _id: string;
      user_id: number;
      username: string;
    }[];
    settings: IChatSettings;
    user_id: number;
    type: number;
  },
  dialog: IDialog,
  messages: {
    docs: IChatDialogMessageReponse[],
    hasNextPage: false
    hasPrevPage: false
    limit: 10
    nextPage: null
    offset: 0
    page: 1
    pagingCounter: 1
    prevPage: null
    totalDocs: 0
    totalPages: 1
  },
  user: {
    user_id: number;
    role: {
      id: number;
    };
  };
}> => {

  if (params.limit) {
    params.per_page = params.limit;
  }

  if (!params.q) {
    delete params.q;
  }

  return socketInstance()
    .get(`/v1/dialogs/${id}`, {
      params
    })
    .then(response => response.data);
}

export const getAvailableUsers = (): Promise<IChatUserInfo[]> => {
  return socketInstance()
    .get('/v1/available-users')
    .then(response => response.data)
    .then(response => response.map((item: {
      id: number;
      username: string;
      avatar: IAvatar | null
    }) => ({
      user_id: item.id,
      username: item.username,
      avatar: item.avatar
    })));
}

export const createPersonalDialog = (userId: number): Promise<IDialog> => {
  return socketInstance()
    .post('/v1/chat', {
      user_id: userId,
      type_id: 1
    })
    .then(response => response.data);
}

export const createGroupDialog = (data: {
  users: number[];
  title: string;
  avatar: File | null;
  description: string;
}): Promise<{
  dialog: IDialog
}> => {
  const formData = new FormData();

  formData.append('title', data.title);
  formData.append('type_id', '2');

  data.users.forEach(user => {
    formData.append('users[]', user.toString());
  });

  if (data.avatar) {
    formData.append('group_ava', data.avatar);
  }

  if (!!data.description) {
    formData.append('description', data.description);
  }

  return socketInstance()
    .post('/v1/chat', formData)
    .then(response => response.data);
};

export const deleteDialog = (id: string): Promise<any> => {
  return socketInstance()
    .delete(`/v1/dialogs/${id}`)
    .then(response => response.data);
}

export const sendFileToChat = (id: string, type: number, files: File[]): Promise<{
  name: string;
  path: string;
  type: number;
}[]> => {
  const formData = new FormData();

  files.forEach(item => {
    formData.append('files', item);
  });

  formData.append('type', type.toString());

  return socketInstance()
    .post(`/v1/chats/${id}/attach`, formData)
    .then(response => response.data.files);
}

export const getDialogMedia = (id: string): Promise<IChatAttachment[]> => {
  return socketInstance().get(`/v1/chats/${id}/media `).then(response => response.data);
}

export const getDialogMembers = (id: string): Promise<IChatAttachment[]> => {
  return socketInstance().get(`/v1/chats/${id}/users `).then(response => response.data);
}

export const getDialogUserInfo = (id: number): Promise<{
  email: string;
  phone: string;
  username: string;
  members: {
    user_id: number;
    username: string;
  }[]
}> => {
  return socketInstance().get(`/v1/users/${id}`).then(response => response.data);
};

export const saveChatSettings = (id: string, data: IChatSettings): Promise<any> => {
  return socketInstance().post(`/v1/chats/${id}/settings `, data).then(response => response.data);
}

export const saveAvatar = (chat_id: string, avatar: File): Promise<any> => {
  const formData = new FormData();

  formData.append('group_ava', avatar);

  return socketInstance().post(`/v1/chats/${chat_id}/upload-avatar`, formData).then(response => response.data);
}
