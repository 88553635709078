import classNames from 'classnames';
import React from 'react';
import { MESSAGE_ATTACHMENTS } from '../../helpers';
import { IChatAttachment, IChatDialogMessageReponse } from './api';
import { CONSTANTS, socket } from './index';
import MessageDoc from './MessageDoc';
import MessageImage from './MessageImage';
import MessageText from './MessageText';
import MessageVideo from './MessageVideo';

interface IProps {
  message: IChatDialogMessageReponse;
  isCurrentUserMessage: boolean;
  senderName: string;
  dialogReceiver: number;
}

const MessageContainer: React.FC<{
  readed?: boolean;
  delivered?: boolean;
  isCurrentUserMessage: boolean;
  className?: string;
}> = ({isCurrentUserMessage, className = '', readed = false, delivered = false, children}) => {

  return (
    <div className={classNames('message-elm', {
      'me': isCurrentUserMessage
    })}>
      <div className={`e ${className}`.trim()}>
        {children}
        <span className={classNames('message-status', {
          'delivered': delivered || !readed,
          'readed': readed && delivered
        })} />
      </div>
    </div>
  );
};

const Message: React.FC<IProps> = (props) => {
  const {message, isCurrentUserMessage, senderName, dialogReceiver} = props;
  const isReceiver = message.to_id === dialogReceiver;
  const id = isReceiver ? message.to_id : message.from_id;
  const readed: boolean = message.statuses.filter(item => item.read !== null && id === item.user_id).length > 0;
  const delivered: boolean = message.statuses.filter(item => item.delivered !== null && id === item.user_id).length > 0;

  if (isReceiver && !delivered) {
    // socket.emit('delivered message', {id: message._id});
  }

  const renderAttachment = (attachment: IChatAttachment, attachmentIndex: number) => {
    const filenameSplit = attachment.filename.split('.'),
      ext = filenameSplit[filenameSplit.length - 1],
      isVideoAccepted = ['webm', 'ogv', 'mp4'].indexOf(ext.toLowerCase()) > -1;

    if (attachment.type === MESSAGE_ATTACHMENTS.DOC || (attachment.type === MESSAGE_ATTACHMENTS.VIDEO && !isVideoAccepted)) {
      return (
        <MessageContainer isCurrentUserMessage={isCurrentUserMessage}
                          delivered={delivered}
                          readed={readed}
                          key={attachmentIndex.toString()}
                          className="doc">
          <MessageDoc attachment={attachment} />
        </MessageContainer>
      );
    }

    if (attachment.type === MESSAGE_ATTACHMENTS.IMAGE) {
      return (
        <MessageContainer isCurrentUserMessage={isCurrentUserMessage}
                          delivered={delivered}
                          readed={readed}
                          key={attachmentIndex.toString()}
                          className="img">
          <MessageImage
            attachment={attachment}
            senderName={senderName} />
        </MessageContainer>
      );
    }

    if (attachment.type === MESSAGE_ATTACHMENTS.VIDEO && isVideoAccepted) {
      return (
        <MessageContainer isCurrentUserMessage={isCurrentUserMessage}
                          delivered={delivered}
                          readed={readed}
                          key={attachmentIndex.toString()}
                          className="img">
          <MessageVideo
            extension={ext}
            attachment={attachment}
            senderName={senderName} />
        </MessageContainer>
      );
    }

    return null;
  };

  switch (message.type) {
    case 2:
      return (
        <React.Fragment>
          {message.attachments.map(renderAttachment)}
        </React.Fragment>
      );
    case 1:
      return (
        <MessageContainer
          delivered={delivered}
          readed={readed}
          isCurrentUserMessage={isCurrentUserMessage}>
          <MessageText
            senderName={senderName}
            message={message.message} />
        </MessageContainer>
      );
    default:
      return null;
  }
};

export default Message;
