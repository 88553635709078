import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import { getContent } from '../../api';
import LoadablePage from "../../components/LoadablePage";
import {IStore} from "../../store";

const PageMedia: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`),
      banner: () => getContent(`media-text-block`),
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const banner: any = loaded.banner;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          banner={banner} />
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageMedia;
