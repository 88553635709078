import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import Input from '../../components/Input';
import { makeCaptchaString } from '../../helpers';
import { PATH } from '../../router';
import { resetPassword } from './api';

interface ICaptchaProps {
  captchaString: string;
}

const Captcha: React.FC<ICaptchaProps> = ({captchaString}) => {
  const captchaContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    captchaContainer.current!.innerHTML = '';

    canvas.width = 100;
    canvas.height = 50;

    ctx!.font = '25px Georgia';
    ctx!.strokeText(captchaString, 0, 30);

    captchaContainer.current!.appendChild(canvas);
  }, [captchaString]);

  return (
    <div ref={captchaContainer} />
  );
};

interface IProps {
  t?: (key: string) => string;
}

interface IState {
  email: string;
  errors: {
    [key: string]: string | null;
  };
  pending: boolean;
  captchaString: string;
  captcha: string;
  success: boolean;
}

class ModalResetPassword extends React.Component<IProps> {
  inputEmail = React.createRef<HTMLInputElement>();
  inputCaptcha = React.createRef<HTMLInputElement>();

  validationSchema = yup.object().shape({
    // @ts-ignore
    email: yup.string().required(this.props.t!('required_field')).email(this.props.t!('incorrect_email_format'))
  });

  state: IState = {
    email: '',
    captcha: '',
    captchaString: makeCaptchaString(6),
    errors: {},
    pending: false,
    success: false
  };

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {value, name} = e.target;

    this.setState({
      [name]: value
    });
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.persist();

    // @ts-ignore
    const {name} = e.target;

    if (e.which === 13 || e.keyCode === 13) {
      if (name === 'email') {
        this.inputCaptcha.current!.focus();
      }

      if (name === 'captcha') {
        this.onResetPassword();
      }
    }
  };

  resetCaptcha = () => {
    this.setState({
      captchaString: makeCaptchaString(6)
    });
  };

  onResetPassword = (): void => {
    const {email, captcha, captchaString, errors: stateErrors} = this.state;

    this.validationSchema.validate({
        email
      }, {
        abortEarly: false
      })
      .then(() => {
        if (captcha !== captchaString) {
          return this.setState({
            errors: {
              ...stateErrors,
              // @ts-ignore
              captcha: this.props.t!('captcha_error')
            }
          });
        }

        this.setState({
          errors: {},
          pending: true
        }, () => {
          resetPassword(email)
            .then(response => {
              this.setState({
                pending: false,
                email: '',
                captcha: '',
                captchaString: makeCaptchaString(6),
                success: true
              });
            })
            .catch(({response}) => {
              // @ts-ignore
              let error = this.props.t!('error_default_message');

              if (response && response.status === 422) {
                // @ts-ignore
                error = this.props.t!('email_not_exists');
              }

              this.setState({
                pending: false,
                success: false,
                errors: {
                  email: error
                }
              });
            });
        });
      })
      .catch(yupErrors => {
        const errors = {};

        yupErrors.inner.forEach((item: ValidationError) => {
          // @ts-ignore
          errors[item.path] = item.message;
        });

        if (captcha !== captchaString) {
          // @ts-ignore
          errors.captcha = this.props.t!('captcha_error');
        }

        this.setState({
          errors
        });
      });
  };

  onClickRegister = (e: React.MouseEvent<HTMLButtonElement>): void => {
    // @ts-ignore
    $('.modal-form-blk').css('display', 'none');

    // @ts-ignore
    $('.overlay').fadeOut('fast');

    // @ts-ignore
    this.props.history.push(PATH.request);
  };

  onClickClose = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      success: false
    });
  };

  render() {
    const {t} = this.props;
    const {pending, errors, email, captcha, captchaString, success} = this.state;

    return (
      <div className="modal-form-blk auth-form" id="forgot">
        <div className="in-blk">
          <a href="#" onClick={this.onClickClose} className="close-modal" />
          <div className="row">
            <div className="col-sm-7">
              <div className="form-simple">
                <h4 className="header-title">{t!('password_recovery')}</h4>
                {success ? (
                  <React.Fragment>
                    <p>
                      {t!('new_password_sent_by_sms')}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Input
                      required={true}
                      ref={this.inputEmail}
                      error={errors.email}
                      label={t!('email')}
                      value={email}
                      name="email"
                      onChange={this.onChangeInput}
                      onKeyPress={this.onKeyPress}
                      type="text" />
                    <div className="row">
                      <div className="col-sm-7">
                        <Input
                          ref={this.inputCaptcha}
                          required={true}
                          error={errors.captcha}
                          label={t!('code_in_picture')}
                          value={captcha}
                          name="captcha"
                          onChange={this.onChangeInput}
                          onKeyPress={this.onKeyPress}
                          type="text" />
                      </div>
                      {/*<div className="col-sm-5 link text-left">*/}
                      {/*  <img src="/images/captcha_test.png" alt=""/>*/}
                      {/*</div>*/}
                      <div className="col-sm-5 link text-left">
                        <Captcha captchaString={captchaString} />
                      </div>
                    </div>
                    <div className="btn-blk row">
                      <div className="col-sm-12 form-group text-left">
                        <button
                          className="btn btn-primary"
                          onClick={this.onResetPassword}
                          style={pending ? {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            borderColor: 'rgba(0,0,0,0.3)'
                          } : {}}
                          disabled={pending}>
                          {t!('recover')}
                        </button>
                      </div>
                      <div className="col-sm-12 text-left">
                        <button
                          onClick={this.onClickRegister}
                          className="btn btn-default"
                          style={pending ? {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            borderColor: 'rgba(0,0,0,0.3)'
                          } : {}}
                          disabled={pending}>
                          {t!('registration')}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="col-sm-5 img">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

// @ts-ignore
export default withTranslation()(withRouter(ModalResetPassword));
