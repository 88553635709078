import React from "react";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import { getChatAvatar, getRandomColor, getShortName } from '../../helpers';
import {IChatUserInfo} from "./index";

const NewDialog: React.FC<{
  availableUsers: IChatUserInfo[],
  onlineUsers: number[],
  createDialog: (userId: number) => void,
  closeDialog: () => void
}> = (props) => {
  const {t} = useTranslation();
  const {availableUsers, onlineUsers, createDialog, closeDialog} = props;
  const [filterText, setFilterText] = React.useState<string>('');

  const onChangeFilterText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterText(e.target.value);
  }

  const onClickCreateDialog = (userId: number) => (e: React.MouseEvent<HTMLDivElement>): void => {
    e.persist();
    e.preventDefault();

    createDialog(userId);
  };

  const onClickCloseDialog = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    closeDialog();
  }

  return (
    <div
      className="write-new-message"
      style={{
        display: 'block'
      }}>
      <div className="head-blk">
        <div className="title-block">{t('new_message')}</div>
        <a
          className="close-write-message"
          onClick={onClickCloseDialog}
        />
        <div className="search-form-blk">
          <div className="row">
            <div className="col-sm-3 label">
              {t('to_whom')}:
            </div>
            <div className="col-sm-9">
              <input
                value={filterText}
                onChange={onChangeFilterText}
                type="text"
                className="form-control"
                placeholder={t('enter_name')}/>
            </div>
          </div>
        </div>
      </div>
      <div className="list-members-blk">
        {availableUsers
          .filter(availableUser => availableUser.username.indexOf(filterText) > -1)
          .map((availableUser, availableUserIndex) => (
            <div
              key={availableUserIndex.toString()}
              className="members-elm open-chat-link"
              onClick={onClickCreateDialog(availableUser.user_id)}>
              <div
                className={classNames('photo', {
                  'online': onlineUsers.indexOf(availableUser.user_id) > -1,
                  'no-avatar': !availableUser.avatar
                })}
                style={{
                  backgroundColor: getRandomColor(availableUser.username),
                  backgroundImage: getChatAvatar(availableUser.avatar!) || ''
                }}>
                {!availableUser.avatar && getShortName(availableUser.username)}
              </div>
              <div className="name">{availableUser.username}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NewDialog;
