import { userInfo } from 'os';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Carousel, { Modal as RIModal, ModalGateway } from 'react-images';
import Modal from '..';
import { BASE_URL } from '../../api';
import Input from '../../components/Input';
import {
  getFilePath,
  getPageCount,
  getStatusName,
  getTaskCategoryName,
  getTaskDirectionParticipate,
  getTaskName,
  getUrlFromString,
  getUserRole,
  getUserThumb,
  USER_ROLES
} from '../../helpers';
import { IAvatar } from '../../interfaces';
import { IResponseExercise } from '../../pages/Cabinet/pages/Home/api';
import { deleteTask } from '../../pages/Cabinet/pages/Log/api';
import { postDeclineTask } from '../../pages/Cabinet/pages/ProfilePerfomance/api';
import { IUserStore } from '../../store';
import moment from 'moment';

interface IPropsAcceptDenyTask {
  id: string;
  user?: IUserStore;
  reportIndex?: number;
  onAccepted: () => void;
  onDenied: () => void;
}

const AcceptDenyTask: React.FC<IPropsAcceptDenyTask> = ({id, onAccepted, onDenied, reportIndex}) => {
  const {t} = useTranslation();
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  // const [commentError, setCommentError] = useState(false);
  const [accept, setAccept] = useState(true);

  const onClickShowComment = (accept: boolean) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();


    setAccept(accept);
    setShowCommentField(!showCommentField);
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {value} = e.target;

    setComment(value);
  };

  const onAccept = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    //if (comment.length < 1) {
    // return setCommentError(true);
    //}

    postDeclineTask({
      type_id: 1,
      member_comment: comment
    }, id)
      .then(() => {
        setShowCommentField(false);
        setComment('');
        onAccepted();
      })
      .catch((err) => {
        alert(err.response?.data?.message || t('error_default_message'));
      });
  };

  const onDeny = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();
    postDeclineTask({
      type_id: 2,
      reject_message: comment
    }, id)
      .then(() => {
        setShowCommentField(false);
        setComment('');
        onDenied();
      }).catch((err) => {
        alert(err.response?.data?.message || t('error_default_message'));
    });
  };

  return (
    <React.Fragment>
      <h4 className="header-title">{t('i_accept')}</h4>
      {!showCommentField && (
        <div className="btn-blk">
          <a href="#"
             className="btn btn-default btn-accept"
             onClick={onClickShowComment(true)}>
            {t('i_accept')}
          </a>
          <a href="#"
             className="btn btn-default btn-denial"
             onClick={onClickShowComment(false)}>
            {reportIndex !== undefined && reportIndex + 1 === 1 ? t('send_to_edit') : t('i_reject')}
          </a>
        </div>
      )}
      {showCommentField && (
        <div className="form-simple" style={{padding: 0}}>
          <div className="form-group combined">
            <Input
              required={false}
              name="comment"
              textarea={true}
              label={t('comment')}
              value={comment}
              onChange={onChangeComment}
              type="text" />
          </div>
          <div className="btn-blk">
            <a href="#"
               className="btn btn-default btn-denial"
               onClick={onClickShowComment(true)}>
              {t('cancel')}
            </a>
            <a href="#"
               className="btn btn-default btn-accept"
               onClick={!accept ? onDeny : onAccept}>
              {t('send')}
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const AcceptDenyTaskEdit: React.FC<IPropsAcceptDenyTask> = ({id, onAccepted, onDenied, reportIndex}) => {
  const {t} = useTranslation();
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);
  const [accept, setAccept] = useState(true);

  const onClickShowComment = (accept: boolean) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (showCommentField) {
      setCommentError(false);
    }
    setAccept(accept);
    setShowCommentField(!showCommentField);
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {value} = e.target;

    if (value.length > 0) {
      setCommentError(false);
    } else {
      setCommentError(true);
    }

    setComment(value);
  };

  const onAccept = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    //if (comment.length < 1) {
    // return setCommentError(true);
    //}

    postDeclineTask({
      type_id: 1,
      member_comment: comment
    }, id)
      .then(() => {
        setShowCommentField(false);
        setComment('');
        setCommentError(false);
        onAccepted();
      })
      .catch(() => {
        alert(t('error_default_message'));
      });
  };

  const onDeny = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (comment.length < 1) {
      return setCommentError(true);
    }

    postDeclineTask({
      type_id: 4,
      mentor_comment: comment
    }, id)
      .then(() => {
        setShowCommentField(false);
        setComment('');
        setCommentError(false);
        onDenied();
      }).catch(() => {
      alert(t('error_default_message'));
    });
  };

  return (
    <React.Fragment>
      <h4 className="header-title">{t('i_accept')}</h4>
      {!showCommentField && (
        <div className="btn-blk">
          <a href="#"
             className="btn btn-default btn-accept"
             onClick={onClickShowComment(true)}>
            {t('i_accept')}
          </a>
          <a href="#"
             className="btn btn-default btn-denial"
             onClick={onClickShowComment(false)}>
            {t('i_reject')}
          </a>
        </div>
      )}
      {showCommentField && (
        <div className="form-simple" style={{padding: 0}}>
          <div className="form-group combined">
            <Input
              required={true}
              error={commentError ? t('required_field') : null}
              name="comment"
              textarea={true}
              label={t('comment')}
              value={comment}
              onChange={onChangeComment}
              type="text" />
          </div>
          <div className="btn-blk">
            <a href="#"
               className="btn btn-default btn-denial"
               onClick={onClickShowComment(true)}>
              {t('cancel')}
            </a>
            <a href="#"
               className="btn btn-default btn-accept"
               onClick={!accept ? onDeny : onAccept}>
              {t('send')}
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

interface IPropsRollbackTask {
  id: string;
  onRollBack: () => void;
  action: {
    model_status_id: number | null;
    model_comment: string;
  };
}

const RollbackTaskMentor: React.FC<IPropsRollbackTask> = ({id, onRollBack, action}) => {
  const {t} = useTranslation();
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);

  const onClickShowComment = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (showCommentField) {
      setCommentError(false);
    }

    setShowCommentField(!showCommentField);
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {value} = e.target;

    if (value.length > 0) {
      setCommentError(false);
    } else {
      setCommentError(true);
    }

    setComment(value);
  };

  const onClickRollback = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (comment.length < 1) {
      return setCommentError(true);
    }

    postDeclineTask({
      type_id: 3,
      reject_message: comment
    }, id)
      .then(() => {
        setShowCommentField(false);
        setComment('');
        setCommentError(false);
        onRollBack();
      }).catch(() => {
      alert(t('error_default_message'));
    });
  };

  return (
    <React.Fragment>
      {action && action.model_status_id === null && (
        <p style={{
          margin: 0
        }}>
          {t('rejection_request_has_been_sent')}
        </p>
      )}
      {action && action.model_status_id === 2 && (
        <div style={{marginBottom: 10}}>
          <p style={{
            margin: 0
          }}>{t('rejection_request_has_been_rejected')}</p>
          {action.model_comment && (
            <p style={{margin: 0}}>{t('comment')}: {action.model_comment}</p>
          )}
        </div>
      )}
      {!action || (action && (action.model_status_id === 2 || action.model_status_id === 1)) ? (
        <React.Fragment>
          {!showCommentField && (
            <div className="btn-blk">
              <a href="#"
                 className="btn btn-default btn-accept"
                 onClick={onClickShowComment}>
                {t('submit_rejection_request')}
              </a>
            </div>
          )}
          {showCommentField && (
            <div className="form-simple" style={{padding: 0}}>
              <div className="form-group combined">
                <Input
                  required={true}
                  error={commentError ? t('required_field') : null}
                  name="comment"
                  textarea={true}
                  label={t('comment')}
                  value={comment}
                  onChange={onChangeComment}
                  type="text" />
              </div>
              <div className="btn-blk">
                <a href="#"
                   className="btn btn-default btn-denial"
                   onClick={onClickShowComment}>
                  {t('cancel')}
                </a>
                <a href="#"
                   className="btn btn-default btn-accept"
                   onClick={onClickRollback}>
                  {t('send')}
                </a>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

interface IPropsAcceptDenyRollbackTask {
  id: string;
  onDenied: () => void;
  role: number;
  action: {
    model_status_id: number | null;
    model_comment: string;
  },
}

const AcceptDenyRollbackTask: React.FC<IPropsAcceptDenyRollbackTask> = ({
                                                                          id,
                                                                          onDenied,
                                                                          role,
                                                                          action
                                                                        }) => {
  const {t} = useTranslation();
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);
  const [type, setType] = useState(0);

  const onClickShowComment = (newType: number) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (newType === 0) {
      setCommentError(false);
    }

    setType(newType);
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {value} = e.target;

    if (value.length > 0) {
      setCommentError(false);
    } else {
      setCommentError(true);
    }

    setComment(value);
  };

  const onSubmit = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (comment.length < 1) {
      return setCommentError(true);
    }

    postDeclineTask(
      {
        type_id: type,
        comment
      },
      id,
      getUserRole(role)
    )
      .then(() => {
        setType(0);
        setComment('');
        setCommentError(false);
        onDenied();
      }).catch(() => {
      alert(t('error_default_message'));
    });
  };

  return (
    <React.Fragment>
      {action && action.model_status_id === 2 && (
        <React.Fragment>
          <p style={{margin: 0}}>{t('rejection_request_has_been_rejected')}</p>
          {action.model_comment && (
            <p style={{margin: 0}}>{t('comment')}: {action.model_comment}</p>
          )}
        </React.Fragment>
      )}
      {action && action.model_status_id === null && (
        <React.Fragment>
          <h4 className="header-title">{t('do_you_accept_rejection_request')}</h4>
          {!type && (
            <div className="btn-blk">
              <a href="#"
                 className="btn btn-default btn-accept"
                 onClick={onClickShowComment(1)}>
                {t('confirm_rejection')}
              </a>
              <a href="#"
                 className="btn btn-default btn-denial"
                 onClick={onClickShowComment(2)}>
                {t('leave_unchanged')}
              </a>
            </div>
          )}
          {!!type && (
            <div className="form-simple" style={{padding: 0}}>
              <div className="form-group combined">
                <Input
                  required={true}
                  error={commentError ? t('required_field') : null}
                  name="comment"
                  textarea={true}
                  label={t('comment')}
                  value={comment}
                  onChange={onChangeComment}
                  type="text" />
              </div>
              <div className="btn-blk">
                <a href="#"
                   className="btn btn-default btn-denial"
                   onClick={onClickShowComment(0)}>
                  {t('cancel')}
                </a>
                <a href="#"
                   className="btn btn-default btn-accept"
                   onClick={onSubmit}>
                  {t('send')}
                </a>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CustomVideo = ({data}: any) => {
  const {source} = data;
  const sourceExt = source.split('.');

  return (
    <div style={{
      width: '100%',
      textAlign: 'center'
    }}>
      <video width="500" height="350" autoPlay={false} controls={true}>
        <source src={source} type={`video/${sourceExt[sourceExt.length - 1]}`} />
      </video>
    </div>
  );
};

interface IState {
  imagesIsOpen: boolean;
  videosIsOpen: boolean;
  reportIndex: number;
  reason: string;
}

interface IProps {
  exercise: IResponseExercise | null;
  locale: string;
  multiple?: boolean;
  allowActions?: boolean;
  role?: number;
  reloadData?: () => void;
  decline?: boolean;
  user?: IUserStore;
  modalIdIndex?: number;
  t: (key: string) => string;
}

class ModalTaskDetail extends React.Component<IProps> {
  state: IState = {
    imagesIsOpen: false,
    videosIsOpen: false,
    // @ts-ignore
    reportIndex: 0,
    reason: ''
  };

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
    this.setState({
      // @ts-ignore
      reportIndex: nextProps.multiple && nextProps.exercise && nextProps.exercise.member_reports && nextProps.exercise.member_reports.length > 1 ? nextProps.exercise.member_reports.length - 1 : 0
    });
    // @ts-ignore
    //nextProps.exercise && console.log('componentWillReceiveProps', nextProps.exercise.member_reports.length)
    // @ts-ignore
    //nextProps.exercise && console.log('nextProps', nextProps)
  }

  onClickImage = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      imagesIsOpen: true
    });
  };

  closeImagesModal = () => {
    this.setState({
      imagesIsOpen: false
    });
  };

  onClickVideo = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      videosIsOpen: true
    });
  };

  closeVideosModal = () => {
    this.setState({
      videosIsOpen: false
    });
  };

  onClickClose = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({

      // @ts-ignore
      reportIndex: this.props.multiple && this.props.exercise && this.props.exercise.member_reports && this.props.exercise.member_reports.length > 1 ? this.props.exercise.member_reports.length - 1 : 0
    });
  };

  onClickReportIndex = (reportIndex: number) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      reportIndex
    });
  };

  onAcceptedOrDeniedTask = (): void => {
    this.setState({
      reportIndex: 0
    }, () => {
      this.hideModal();

      this.props.reloadData && this.props.reloadData();
    });
  };

  hideModal = () => {
    $('.modal-form-blk').fadeOut('fast');
    $('.overlay').fadeOut('fast');
  };

  onClickDeleteTask = (exercise: IResponseExercise, role: number, locale: string) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (window.confirm(this.props.t('delete_task_confirmation_message'))) {

      deleteTask(getUserRole(role), exercise.member_id, exercise.id)
        .then(() => {
          this.setState({
            reportIndex: 0
          }, () => {
            this.hideModal();

            this.props.reloadData && this.props.reloadData();
          });

        })
        .catch(() => {
          alert(this.props.t('error_default_message'));
        });
    }
  };

  onSmart = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

  };

  getSmart = (text: string) => {
    return (
      <React.Fragment>{' | '}
        <a
          onClick={this.onSmart}
          className="smart-aim-link"
          data-title={this.props.t('smart_goal')}
          data-text={text}>
          {this.props.t('smart_goal')}
        </a>
      </React.Fragment>
    );
  };

  getMemberReportComment = (comment: string): string => {
    const urlString = getUrlFromString(comment);

    if (!!urlString) {
      return comment;
    }

    const link = ` <a href="${urlString}" target="_blank">${urlString}</a> `;

    if(comment) {
      return comment.replace(urlString, link);
    }else{
      return '-'
    }
  };

  render() {
    const {exercise, locale, multiple, allowActions, role, modalIdIndex, t} = this.props;
    const {imagesIsOpen, videosIsOpen, reportIndex} = this.state;
    let memberReport = null;

    const images: {
      source: string;
    }[] = [];
    const videos: {
      source: string;
    }[] = [];

    // @ts-ignore
    if (exercise && (!!exercise.member_report || (exercise.member_reports && exercise.member_reports.length > 0))) {
      // @ts-ignore
      memberReport = exercise.member_reports[reportIndex];
      if (memberReport != null) {
        // @ts-ignore
        memberReport.images && memberReport.images.forEach((image: IAvatar) => {
          images.push({
            source: getUserThumb(image, false, false) || ''
          });
        });
        // @ts-ignore
        memberReport.videos && memberReport.videos.forEach((video: IAvatar) => {
          const splitedName = video.name.split('.');
          const ext = splitedName[splitedName.length - 1];
          let source = null;

          // @ts-ignore
          if (!!video.mp4) source = video.mp4;
          // @ts-ignore
          else if (!!video.webm) source = video.webm;
          else if (['webm', 'ogv', 'mp4'].indexOf(ext.toLowerCase()) > -1) source = getUserThumb(video, false, false) || '';

          // @ts-ignore
          if (!!video.is_archive && !!video.archive_full_url) source = video.archive_full_url;

          if (source) {
            videos.push({
              source
            });
          }
        });
      }
    }

    let date = '';
    let dateNumber = 0;
    // @ts-ignore
    if (exercise?.status_id === 6 && exercise?.refused_task_sent_at) {
      // @ts-ignore
      const duration = moment.duration(moment(exercise?.refused_task_sent_at).diff(moment()));
      date = (duration.asHours() + 24).toFixed(0);
      dateNumber = duration.asHours() + 24;
    }

    return (
      <Modal>
        <div className="modal-form-blk half-fill auth-form" id={`task-more${!!modalIdIndex ? `-${modalIdIndex}` : ''}`}
             style={{height: 510}}>
          <div className="in-blk">
            {/*@ts-ignore*/}
            {exercise && exercise.is_control_report === 1 ? <i className="control-icon" /> : null}
            <a href="#" onClick={this.onClickClose} className="close-modal close-black" />
            {exercise?.status_id === 6 && dateNumber > 0 ? (
              <div className="days remain-hours-timer">
                <span className="counter" data-count={date}>{date}</span> часа
              </div>
            ) : null}
            {/*@ts-ignore*/}
            {exercise && (((exercise.member_reports && exercise.member_reports.length === 0) && role && ([USER_ROLES.expert.id, USER_ROLES.coordinator.id].indexOf(role) > -1)) || (exercise.is_requested_to_delete === 1 && role)) && (
              <a href="#" className="delete-task-link" onClick={this.onClickDeleteTask(exercise, role, locale)} />
            )}
            <div className="row task-info-modal">
              <div className="col-sm-6 fill">
                {exercise && memberReport && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-sm-6">
                        <h4 className="header-title">
                          {t('execution')}
                        </h4>
                      </div>
                      {multiple && (
                        <div className="col-sm-6 text-right">
                          <div className="dropdown show form-group">
                            {/*@ts-ignore*/}
                            {exercise.member_reports.length > 1 ? (
                              <a className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {reportIndex + 1} {t('version').toUpperCase()}
                              </a>
                            ) : (
                              <span className="btn">
                                  {reportIndex + 1} {t('version').toUpperCase()}
                                </span>
                            )}
                            {/*@ts-ignore*/}
                            {exercise.member_reports.length > 1 && (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                {/*@ts-ignore*/}
                                {exercise.member_reports.map((item: any, itemIndex: number) => {
                                  if (reportIndex === itemIndex) {
                                    return null;
                                  }

                                  return (
                                    <a
                                      className="dropdown-item"
                                      key={itemIndex.toString()}
                                      onClick={this.onClickReportIndex(itemIndex)}>
                                      {itemIndex + 1} {t('version').toUpperCase()}
                                    </a>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="desc-blk">
                      <div className="e">
                        <div className="name">{t('description')}</div>
                        <div className="txt" dangerouslySetInnerHTML={{
                          __html: this.getMemberReportComment(
                            (exercise && exercise.member_reports && exercise.member_reports[reportIndex] != null) ?
                              //@ts-ignore
                              exercise.member_reports[reportIndex].comment
                              :
                              memberReport.comment
                          )
                        }}>
                        </div>
                      </div>
                      {memberReport.doc_comments && memberReport.doc_comments.length > 0 && (
                        <div className="e">
                          <div className="name">{t('attached_description')}</div>
                          {memberReport.doc_comments.map((item: any, index: number) => (
                            <a href={getFilePath(item)}
                               key={index.toString()}
                               target="_blank"
                               className="p c" />
                          ))}
                        </div>
                      )}
                      {memberReport.images && memberReport.images.length > 0 && (
                        <div className="e">
                          <div className="name">Фото</div>
                          {memberReport.images.map((image: IAvatar, index: number) => (
                            <a href="#"
                               key={index.toString()}
                               onClick={this.onClickImage}
                               className="p"
                               style={{
                                 backgroundImage: getUserThumb(image, false) || ''
                               }} />
                          ))}
                        </div>
                      )}
                      {memberReport.videos && memberReport.videos.length > 0 && (
                        <div className="e">
                          <div className="name">{t('video')}</div>
                          {memberReport.videos.map((video: IAvatar, index: number) => {
                            const splitedName = video.name.split('.');
                            const ext = splitedName[splitedName.length - 1];
                            let source = null;

                            // @ts-ignore
                            if (!!video.mp4) source = video.mp4;
                            // @ts-ignore
                            else if (!!video.webm) source = video.webm;
                            else if (['webm', 'ogv', 'mp4'].indexOf(ext.toLowerCase()) > -1) source = getUserThumb(video, false, false) || '';

                            if (source) {
                              return (
                                <a href="#"
                                   key={index.toString()}
                                   onClick={this.onClickVideo}
                                   className="p v"
                                   style={{
                                     backgroundImage: video.thumb || ''
                                   }} />
                              );
                            }

                            return (
                              <a href={getUserThumb(video, false, false) || ''}
                                 download
                                 key={index.toString()}
                                 className="p v"
                                 style={{
                                   backgroundImage: video.thumb || ''
                                 }} />
                            );
                          })}
                        </div>
                      )}
                      {memberReport.certificates && memberReport.certificates.length > 0 && (
                        <div className="e">
                          <div className="name">{t('certificates')}</div>
                          {memberReport.certificates.map((item: any, index: number) => (
                            <a href={getFilePath(item)}
                               key={index.toString()}
                               target="_blank"
                               className="p c" />
                          ))}
                        </div>
                      )}
                      {memberReport.links && memberReport.links.length > 0 && (
                        <div className="e">
                          <div className="name">{t('links')}</div>
                          {memberReport.links.map((item: any, index: number) => (
                            <div className="link"><a href={item.link}
                                                     key={index.toString()}
                                                     target="_blank"
                                                     className="l">{item.link}</a></div>
                          ))}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="col-sm-6">
                <div className="task-info">
                  {exercise && (
                    <React.Fragment>
                      <div className="direct-participate-text">{getTaskDirectionParticipate(exercise, locale)}</div>
                      <div className="cat" style={{
                        marginRight: 20
                      }}>
                        {getTaskCategoryName(exercise, locale)}
                        {` | `}
                        {getTaskName(exercise, locale)}
                        {getPageCount(exercise, locale)}
                        {/* @ts-ignore */}
                        {exercise.smart_aim && this.getSmart(exercise.smart_aim.text, locale)}
                      </div>
                      {/* @ts-ignore */}
                      <div className="plan">{exercise.aims && exercise.aims[0].title}</div>
                      <div className="cat">
                        {t('status')}
                      </div>
                      {exercise && exercise.member_reports && (exercise.member_reports[reportIndex + 1] == null) ? (
                          <div className="plan">
                            {exercise.status}
                            {memberReport && memberReport.reject_message && `(${memberReport.reject_message})`}
                          </div>
                        ) :
                        (<div className="plan">
                          {getStatusName(exercise.status_id, locale)}
                          {memberReport && memberReport.reject_message && `(${memberReport.reject_message})`}
                        </div>)
                      }
                      {
                        exercise && exercise.task_actions && exercise.task_actions.length > 0 && exercise.status_id === 1 && (
                          <>
                            <div className="cat">
                                Причина переноса
                              </div>
                              <div className="plan">
                                {exercise.task_actions.map((item: any) => {
                                  return item.member_comment
                                })}
                            </div>
                            <div className="cat">
                              Конечная дата переноса
                            </div>
                            <div className="plan">
                                {exercise && exercise.task_actions && exercise.task_actions.length ? exercise.task_actions.map((item: any) => {
                                  return (<span>
                                    {
                                      moment(item.date_at, 'YYYY-MM-DD').format(
                                        'DD.MM.YYYY'
                                      )
                                    }
                                  </span>)
                                }) : null}
                            </div>

                            {exercise && exercise.reason && exercise.reason.map((item: any, index: number) => {
                              return <a href={getFilePath(item)}
                                        target="_blank"
                                        key={index.toString()}
                                        className="p c"
                                        // style={{
                                        //   backgroundImage: getUserThumb(item, false) || ''
                                        // }}
                                        >Документ</a>
                            })}
                            {/* <a href={`${BASE_URL}/${exercise?.reason.path}/${item.name}`}
                               target="_blank"
                               className="p c" /> */}
                          </>
                        )
                      }

                      {/*<div className="btn-blk">*/}
                      {/*  <a href="#" className="btn btn-default">Перенести</a>*/}
                      {/*  <a href="#" className="btn btn-default">Написать наставнику</a>*/}
                      {/*</div>*/}
                      {exercise && memberReport && allowActions && role && (
                        <React.Fragment>
                          {/* @ts-ignore */}
                          {role === USER_ROLES.mentor.id && (exercise.member_reports[reportIndex + 1] == null) && (
                            <React.Fragment>
                              {(exercise.status_id === 4 || exercise.status_id === 7) && (exercise.can_be_reworked === 0) && (
                                <AcceptDenyTask
                                  onDenied={this.onAcceptedOrDeniedTask}
                                  onAccepted={this.onAcceptedOrDeniedTask}
                                  reportIndex={reportIndex}
                                  id={exercise.id.toString()} />
                              )}
                              {(exercise.status_id === 4 || exercise.status_id === 7) && (exercise.can_be_reworked === 1) && (
                                <AcceptDenyTaskEdit
                                  onDenied={this.onAcceptedOrDeniedTask}
                                  reportIndex={reportIndex}
                                  onAccepted={this.onAcceptedOrDeniedTask}
                                  id={exercise.id.toString()} />
                              )}
                              {exercise.status_id === 5 && (
                                <RollbackTaskMentor
                                  // @ts-ignore
                                  action={exercise.request_reject_confirmed_task_action}
                                  onRollBack={this.onAcceptedOrDeniedTask}
                                  id={exercise.id.toString()} />
                              )}
                            </React.Fragment>
                          )}
                          {[USER_ROLES.expert.id, USER_ROLES.coordinator.id].indexOf(role) > -1 && (
                            <React.Fragment>
                              {/*@ts-ignore*/}
                              {exercise.status_id === 5 && !!exercise.request_reject_confirmed_task_action && (
                                <AcceptDenyRollbackTask
                                  // @ts-ignore
                                  action={exercise.request_reject_confirmed_task_action}
                                  onDenied={this.onAcceptedOrDeniedTask}
                                  role={role}
                                  id={exercise.id.toString()} />
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalGateway>
          {imagesIsOpen ? (
            <RIModal onClose={this.closeImagesModal}
                     closeOnBackdropClick={false}>
              <Carousel views={images} />
            </RIModal>
          ) : null}
        </ModalGateway>
        <ModalGateway>
          {videosIsOpen ? (
            <RIModal onClose={this.closeVideosModal}
                     closeOnBackdropClick={false}>
              <Carousel views={videos} components={{View: CustomVideo}} />
            </RIModal>
          ) : null}
        </ModalGateway>
      </Modal>
    );
  }
}

// @ts-ignore
export default withTranslation()(ModalTaskDetail);
