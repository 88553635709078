import React from 'react';
import Loadable from 'react-loadable';
import LoadablePage from '../../components/LoadablePage';

const PageForbidden: React.FC = (props) => {
  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page')
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageForbidden;
