import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {getProfile, getSite} from './api';
import { getRequestsCount } from "./components/HeaderDefault/api";
import LayoutDefault from "./layouts/Default";
import { AClearUser, ASetCounts, ASetParticipantsCount, ASetUser } from './store';

export const PATH = {
  home: '/',
  request: '/request',
  rules: '/rules',
  termsofuse: '/rules/termsofuse',
  codeks: '/rules/codeks',
  faq: '/faq',
  parents: '/for-parents',
  news: '/news',
  winners: '/winners',
  confirmEmail: '/confirm-email',
  search_results: '/search',
  news_detail: '/news/:id',
  program: '/program',
  levels: '/program/levels/:type?',
  awards: '/program/awards',
  members: '/program/members/:type?',
  ways: '/program/ways/:type?',
  moderation: '/moderation',
  contact: '/contact',
  vidioguide: '/vidioguide',
  smi: '/smi',
  mentors: '/mentors',
  regionalCoordinators: '/regional-coordinators',
  medalist: '/medalist',
  centers: '/centers',
  smiDetail: '/smi/:id',
  media: '/media',
  map: '/map',
  modalSuccessPasswordReset: '/success/reset-password',
  cabinet: '/cabinet',
  cabinetMio: '/cabinet/mio',
  cabinetFAQ: '/cabinet/faq',
  cabinetSettings: '/cabinet/settings',
  cabinetNotifications: '/cabinet/notifications',
  cabinetLog: '/cabinet/log',
  cabinetFiles: '/cabinet/files',
  cabinetTrophy: '/cabinet/trophy',
  cabinetReport: '/cabinet/log/report',
  cabinetReportCreate: '/cabinet/report',
  cabinetRegistry: '/cabinet/registry',
  cabinetRegistryDeactivated: '/cabinet/registry/deactivated',
  cabinetRegistryFinished: '/cabinet/registry/finished',
  cabinetRegistryUnfinished: '/cabinet/registry/unfinished',
  cabinetRegistryFinishedNotMedalist: '/cabinet/registry/finished-notmedalist',
  cabinetRegistryFinished14year: '/cabinet/registry/finished-14year',
  cabinetRegistryFinished15year: '/cabinet/registry/finished-15year',
  cabinetReRegistries: '/cabinet/registry/re',
  cabinetRegistryAllList: '/cabinet/registry/all',
  cabinetRegistryApplicant: '/cabinet/registry/applicant',
  cabinetRegistryRepeated: '/cabinet/registry/repeated',
  cabinetRegistryWinners: '/cabinet/registry/winners',
  cabinetRegistryLimited: '/cabinet/registry/limited',
  cabinetRegistryMentorsList: '/cabinet/registry/mentors',
  cabinetDiary: '/cabinet/diary',
  cabinetAchievement: '/cabinet/achievement',
  cabinetProfileInfoEdit: '/cabinet/profile/edit',
  cabinetProfileChangeLevel: '/cabinet/profile/editlevel/:id',
  cabinetProfileInfoEditExternal: '/cabinet/profile/edit/:id',
  cabinetProfileInfo: '/cabinet/profile/:profile/:id/info',
  cabinetProfileInfoNew: '/cabinet/profile/:id/info-new',
  cabinetProfileSchedule: '/cabinet/profile/:id/schedule',
  cabinetProfilePerfomance: '/cabinet/profile/:id/perfomance',
  forbidden: '/403',
  notFound: '/404',
  rest: '*',
  generate: function (path: string, ...rest: string[]): string {
    let pathsArray = path.split('/');
    let index = 0;

    pathsArray = pathsArray.map(item => {
      if (item.indexOf(':') > -1) {
        const url = rest[index];

        index += 1;

        return url;
      }

      return item;
    });

    return pathsArray.join('/');
  }
};

const Router = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    var executed = false;
    if (!executed) {
      executed = true;
      getRequestsCount()
      .then(response => {
        dispatch(ASetParticipantsCount(response.count));
        dispatch(ASetCounts({...response}));
      }).catch(() => {
      });
    }

    if (!cookie.load('token')) {
      setPending(false);
    } else {
      getProfile()
        .then(response => {
          console.log('res', response)
          dispatch(ASetUser({
            authenticated: true,
            id: response.id,
            filled: response.filled,
            blocked: response.blocked,
            days_remaining_type: response.days_remaining_type,
            days_remaining: response.days_remaining,
            profile_filled_at: response.profile_filled_at,
            limited_access: response.limited_access,
            first_name: response.first_name,
            last_name: response.last_name,
            program_level_hours_remaining: response.program_level_hours_remaining,
            user_role_id: response.user_role_id,
            user_role: response.user_role,
            avatar: response.avatar,
            area_id: response.area_id,
            is_password_updated: !!response.is_password_updated,
            is_agree: !!response.is_agree,
            is_show_hint: !!response.is_show_hint,
            email: response.email,
            new_notification_count: response.new_notification_count,
            notification_count: response.notification_count,
            new_trophies_count: response.new_trophies_count ? response.new_trophies_count : 0,
            winner: response.member ? response.member.winner : null,
            program_level_id: response.member ? response.member.program_level_id : 0
          }));

          getSite().then((data) => {
            if (data.status_id === 0 && (
                data.condition_type === 0 || (
                    data.condition_type === 1 && !data.roles.find(role => role === response.user_role_id)
                )
            )) {
              cookie.remove('token', {
                path: '/'
              });

              dispatch(AClearUser());
            }

            setPending(false);
          })
        })
        .catch((error) => {
          cookie.remove('token', {
            path: '/'
          });

          dispatch(AClearUser());
          setPending(false);
        })
    }
  }, []);

  if (pending) {
    return (
      <React.Fragment>
        <div id="top" />
        <div className="preloader">
          <div className="preloader_image">
            <object data="/images/loader.svg" type="image/svg+xml" id="loader" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          component={LayoutDefault} />
      </Switch>
    </BrowserRouter>
  )
};

export default Router;
