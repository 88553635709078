import instance from "../../api";
import {INotification} from "../../interfaces";

export const getRequestsCount = (): Promise<any> => {
  return instance(false).get('/v1/count-members').then(response => response.data.content);
};

export const getNotifications = (): Promise<INotification[]> => {
  return instance().get('/v1/notifications').then(response => response.data.data);
}

export const PostResponse = (id: string, data:any): Promise<any> => {
  return instance().post(`/v1/mentor/members/${id}/send-notification`, data).then(response => response.data.data);
}

export const setNotificationAsViewed = (id: string): Promise<any> => {
  return instance().get(`/v1/notifications/${id}`).then(response => response.data);
}

export const getMemberTask = (taskId: any, slug = 'mentor'): Promise<any> => {
  return instance().get(`/v1/${slug}/tasks/${taskId}`).then(response => response.data.data);
};
