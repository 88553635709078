import instance from "../../../../api";
import {IArea} from "../../../../interfaces";

export const updateProfile = (data: any, slug?: string, profile?: string, id?: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key: string) => {
    if (key === 'resumes') {
      data[key].forEach((file: File) => {
        formData.append('resumes[]', file);
      });
    } else if (key === 'docs') {
      data[key].forEach((file: File) => {
        formData.append('docs[]', file);
      });
    } else if (key === 'reference_criminal') {
      data[key].forEach((file: File) => {
        formData.append('reference_criminal[]', file);
      });
    } else if (key === 'reference_psycho') {
      data[key].forEach((file: File) => {
        formData.append('reference_psycho[]', file);
      });
    } else if (key === 'reference_narco') {
      data[key].forEach((file: File) => {
        formData.append('reference_narco[]', file);
      });
    } else if (key === 'diplomas') {
      data[key].forEach((file: File) => {
        formData.append('diplomas[]', file);
      });
    } else if (key === 'agr_parents') {
      data[key].forEach((file: File) => {
        formData.append('agr_parents[]', file);
      });
    } else if (key === 'docs_parents') {
      data[key].forEach((file: File) => {
        formData.append('docs_parents[]', file);
      });
    } else if (key === 'references') {
      data[key].forEach((file: File) => {
        formData.append('references[]', file);
      });
    } else if (key === 'bbk_decisions') {
      data[key].forEach((file: File) => {
        formData.append('bbk_decisions[]', file);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  if (id) {
    var jsonData = JSON.stringify(Object.fromEntries(formData));
    return instance().post(`/v1/${slug}/${profile}/${id}`, formData, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'content-type': 'text/json'
      }
    }).then(response => response.data.data)
  }

  return instance().post('/v1/profile/update', formData).then(response => response.data.data);
};

export const getMember = (slug: string, profile: string, id: number) => {
  return instance().get(`/v1/${slug}/${profile}/${id}`).then(response => { return { ...response.data.data, ...response.data.data.user } });
};

export const getCities = (area: any, type: any) => {
  return instance().get(`/v1/member/cities?area=${area}&type=${type}`).then(response=> {return response.data.data})
}

export const getEducation = (city: any, eci: any) => {
  return instance().get(`/v1/member/educations?city_id=${city}&education_category_id=${eci}`).then(response=> {console.log(response); return response.data.data})
}

export const deleteFile = (id: number) => {
  return instance().delete(`/v1/user/delete-file/${id}`).then(response => response.data);
};

export const deleteAvatar = (): Promise<any> => {
  return instance().delete('v1/delete-avatar').then(response => response.data);
}

export const getDistricts = (areaId: number): Promise<IArea[]> => {
  return instance(false)
      .get(`/v1/areas/${areaId}/districts`)
      .then((response) => response.data.data);
};

export const getCityById = (areaId: number, districtId: number): Promise<IArea[]> => {
  return instance(false)
      .get(`/v1/cities?area_id=${areaId}&district_id=${districtId}`)
      .then((response) => response.data.data);
};

export const getNationalities = (): Promise<any> => {
  return instance(false)
      .get("/v1/nationalities")
      .then(response => response.data);
}
