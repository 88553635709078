import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { match as IRRDMatch, Redirect, useRouteMatch } from 'react-router-dom';
import LoadablePage from '../../components/LoadablePage';
import { PATH } from '../../router';
import { IStore } from '../../store';
import { getSMIDetail } from './api';

interface IMatch extends IRRDMatch {
  params: {
    id: string;
  };
}

const PageSMIDetail: React.FC = (props) => {
  const match: IMatch = useRouteMatch();
  const locale = useSelector((state: IStore) => state.locale);
  const {id} = match.params;

  if (!id || id === ':id' || isNaN(parseInt(id))) {
    return <Redirect to={PATH.notFound} />;
  }

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`),
      detail: () => getSMIDetail(parseInt(id), {locale})
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          detail={loaded.detail} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageSMIDetail;
