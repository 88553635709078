import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { getContent } from '../../api';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';
import { getAreas } from '../Request/api';

const PageSMI: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({

    loader: {
      page: () => import(`./page`),
      banner: () => getContent(`centers-list-block`),
      areas: () => getAreas(1).then(response =>
        response.map(item => ({
          // @ts-ignore
          label: item[`title_${locale}`],
          value: item.id
        })))
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const banner: any = loaded.banner;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          banner={banner}
          areas={loaded.areas} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageSMI;
