import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import LoadablePage from '../../components/LoadablePage';
import { IStore, IUserStore } from '../../store';

// Региональный кординатор:
//email: akhmetov@mail.ru
//password: 123123
//email: sergeyev@mail.ru
//password: 123123

// Эксперт:
//email: ivanov@mail.ru
//password: 123123
//email: petrov@mail.ru
//password: 123123

// Наставник:
//email: nastavnik2@test.kz
//password: 123123

// Ученик:
//email: arusuleiman155an@gmail.com
//password: 123123

interface IProps {
  user: IUserStore;
  locale: string;
}

const CabinetPage: React.FC<IProps> = ({locale, user, ...props}) => {
  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page')

    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      return (
        <Page
          {...loadedProps}
          user={user}
          locale={locale} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

const mapStateToProps = (store: IStore) => ({
  user: store.user,
  locale: store.locale
});

export default connect(mapStateToProps, null)(CabinetPage);

/* Наставники:
Пароль: 123123
--nastavnik1@test.kz
nastavnik2@test.kz
nastavnik3@test.kz
nastavnik4@test.kz
nastavnik5@test.kz
nastavnik6@test.kz
nastavnik7@test.kz
nastavnik8@test.kz
nastavnik9@test.kz
nastavnik10@test.kz
nastavnik12@test.kz
nastavnik13@test.kz
nastavnik14@test.kz
nastavnik15@test.kz
nastavnik16@test.kz
@yertaysharipkanov Региональный координатор:
Пароль: 123123
1. akhmetov@mail.ru
2. aliyev@mail.ru
3. omarov@mail.ru
4. sergeyev@mail.ru
5. mironov@mail.ru
6. ilyusha@mail.ru
7. amanov@mail.ru
8. gazizov@mail.ru
9. aibekov@mail.ru
10. armanov@mail.ru
11. viktorov@mail.ru
12. sultanov@mail.ru
13. alexandr@mail.ru
14. aleksey@mail.ru
15. alikhanov@mail.ru
16. amirov@mail.ru
17. kanatov@mail.ru
@yertaysharipkanov Эксперт:
Пароль: 123123
1. ivanov@mail.ru
2. petrov@mail.ru
3. andreyev@mail.ru
4. karimov@mail.ru
5. zhanibekov@mail.ru
6. grisha@mail.ru
7. vasya@mail.ru
8. romanov@mail.ru
9. ospanov@mail.ru
10. batyrov@mail.ru
11. arturov@mail.ru
12. meiramov@mail.ru
13. anuarbekov@mail.ru
14. antonov@mail.ru
15. daniyarov@mail.ru
16. talgatov@mail.ru
17. maratov@mail.ru
Заявки уже и так имеются, это и есть потенциальные Участники.  Главное, только, чтобы Письмо с уведомлением им не ушло. */
