import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import LoadablePage from "../../components/LoadablePage";
import {IStore} from "../../store";
import {match as IRRDMatch, Redirect, useRouteMatch} from 'react-router-dom';
import {PATH} from "../../router";
import {getNewsDetail} from "./api";

interface IMatch extends IRRDMatch {
  params: {
    id?: string;
  };
}

const PageNewsDetail: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const match: IMatch = useRouteMatch();
  const {id} = match.params;

  if (!id || id === ':id' || isNaN(parseInt(id))) {
    return <Redirect to={PATH.notFound}/>
  }

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      data: () => getNewsDetail(parseInt(id),{locale}),
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          data={loaded.data}/>
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageNewsDetail;
