import {IAvatar} from "../../interfaces";

const SET_MESSAGES = 'SET_MESSAGES';

export interface IDialog {
  chat_id: string;
  user_id: number;
  photo: IAvatar | null;
  title: string;
  to_user_id: number;
  to_user_last_seen: string;
  type: number;
  __v: number;
  _id: string;
}
interface IDialogsAction {
  type: string;
  payload: any;
}
interface ISetDialogsAction extends Partial<IDialogsAction> {
  payload: IDialog[]
}

export const ASetDialogs = (payload: IDialog[]): ISetDialogsAction => ({
  type: SET_MESSAGES,
  payload,
});

const initialDialogsState: IDialog[] = [];

const RDialogs = (state = initialDialogsState, action: IDialogsAction) => {
  switch (action.type) {
    case SET_MESSAGES:
      return [...action.payload];
    default:
      return state;
  }
}

export default RDialogs;
