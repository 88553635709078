import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { match as IRRDMatch, Redirect, useRouteMatch } from 'react-router-dom';
import { getContent } from '../../../api';
import LoadablePage from '../../../components/LoadablePage';
import { PATH } from '../../../router';
import { IStore } from '../../../store';

interface IMatch extends IRRDMatch {
  params: {
    type?: string;
  };
}

const PageMembers: React.FC = (props) => {
  const match: IMatch = useRouteMatch();
  const locale = useSelector((state: IStore) => state.locale);
  if (!match.params.type) {
    return <Redirect to={PATH.generate(PATH.members, 'member')} />;
  }

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      content: () => import(`./content/${match.params.type}`),
      data: () => getContent(`${match.params.type}-page`),
      // data: () => getContent(`contents-${match.params.type}-block`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const Content: any = loaded.content.default;
      const data: any = loaded.data;

      return (
        <Page
          {...loadedProps}
          type={match.params.type}
          locale={locale}
          data={data}
          content={Content} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageMembers;
