import React from 'react';
import {Redirect} from 'react-router-dom';
import {LoadingComponentProps} from 'react-loadable';
import './styles.scss';
import {PATH} from "../../router";

interface IProps extends LoadingComponentProps {

}

const LoadablePage: React.FC<IProps> = ({error, ...rest}) => {
  if (error) {
    console.log(error);
    console.log(error.response);
    const {response} = error;

    if (response && response.status === 404) {
      return <Redirect to={PATH.notFound} />
    }

    if (response && response.data && response.data.message === "Server Error") {
      return <Redirect to={PATH.notFound} />
    }
  }

  // TODO Page loader
  return (
    <div className="page-loader">
      <object data="/images/loader.svg" type="image/svg+xml" className="page-loader__object" />
    </div>
  );
};

export default LoadablePage;
