import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';

const PageWinners: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps}
          locale={locale} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageWinners;
