import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { getContent } from '../../api';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';
import { getFaqDetails } from '../Faq/api';

const PageParents: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      data: () => getContent(`for-parents-page`),
      banner: () => getContent(`for-parents-block`),
      faq: getFaqDetails
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const data: any = loaded.data;
      const faq: any = loaded.faq;
      const banner: any = loaded.banner;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          banner={banner}
          //@ts-ignore
          faq={faq.filter(item => item.category_id === 349)}
          data={data} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageParents;
