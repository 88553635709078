import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import { getContent } from '../../api';
import LoadablePage from "../../components/LoadablePage";
import {IStore} from "../../store";
import { getFaqDetails } from '../Faq/api';

const PageRules: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`),
      data: () => getContent(`rules-page`),
      faq: getFaqDetails,
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const data: any = loaded.data;
      const faq: any = loaded.faq;

      return (
        <Page
          {...loadedProps}
          data={data}
          locale={locale}
          //@ts-ignore
          faq={faq.filter(item=>item.category_id===349)} />
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageRules;
