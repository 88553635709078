import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Chat from '../../components/Chat';
import FooterDefault from '../../components/FooterDefault';
import HeaderDefault from '../../components/HeaderDefault';
import NavigationDefault from '../../components/NavigationDefault';
//Cabinet
import PageCabinet from '../../pages/Cabinet';
import PageCenters from '../../pages/Centers';
import PageContact from '../../pages/Contact';
import PageFaq from '../../pages/Faq';
import PageForbidden from '../../pages/Forbidden';
import PageHome from '../../pages/Home';
import PageMap from '../../pages/Map';
import PageMedia from '../../pages/Media';
import PageMentors from '../../pages/Mentors';
//Footer Pages
import PageModeration from '../../pages/Moderation';
import PageNews from '../../pages/News';
import PageNewsDetail from '../../pages/NewsDetail';
import PageNotFound from '../../pages/NotFound';
import PageParents from '../../pages/Parents';
import PageProgram from '../../pages/Program';
// Program Pages
import PageAwards from '../../pages/Program/Awards';
import PageLevels from '../../pages/Program/Levels';
import PageMembers from '../../pages/Program/Members';
import PageWays from '../../pages/Program/Ways';
import PageRequest from '../../pages/Request';
// Rules Pages
import PageRules from '../../pages/Rules';
import PageCodeks from '../../pages/Rules/Codeks';
import PageTermsOfUse from '../../pages/Rules/Termsofuse';
import PageSearchResults from '../../pages/Search';
import PageSMI from '../../pages/SMI';
import PageRegionalCoordinators from '../../pages/RegionalCoordinators';
import PageSMIDetail from '../../pages/SMIDetail';
import SuccessPasswordReset from '../../pages/SuccessPasswordReset';
import PageVideoguide from '../../pages/Videoguide';
import PageWinners from '../../pages/Winners';
import { PATH } from '../../router';
import { IStore } from '../../store';
import './styes.scss';
import {EmailConfirm} from "../../pages/EmailConfirm";
import PageMedalists from '../../pages/Medalists';

const LayoutDefault = () => {
  const user = useSelector((store: IStore) => store.user);
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    $('html, body').animate({scrollTop: 0}, 'slow');
  }, [history.location.pathname]);

  return (
    <div className="wrapper-default">
      <HeaderDefault />
      <NavigationDefault />
      <Switch>
        <Route
          path={PATH.request}
          exact={true}
          component={PageRequest} />
        <Route
          path={PATH.home}
          exact={true}
          component={PageHome} />
        <Route
          path={PATH.program}
          exact={true}
          component={PageProgram} />
        <Route
          path={PATH.awards}
          exact={true}
          component={PageAwards} />
        <Route
          path={PATH.levels}
          component={PageLevels} />
        <Route
          path={PATH.members}
          component={PageMembers} />
        <Route
          path={PATH.search_results}
          exact={true}
          component={PageSearchResults} />
        <Route
          path={PATH.ways}
          component={PageWays} />
        <Route
          path={PATH.rules}
          exact={true}
          component={PageRules} />
        <Route
          path={PATH.codeks}
          exact={true}
          component={PageCodeks} />
        <Route
          path={PATH.winners}
          exact={true}
          component={PageWinners} />
        <Route
          path={PATH.news}
          exact={true}
          component={PageNews} />
        <Route
          path={PATH.news_detail}
          component={PageNewsDetail} />
        <Route
          path={PATH.faq}
          exact={true}
          component={PageFaq} />
        <Route
          path={PATH.smi}
          exact={true}
          component={PageSMI} />
        <Route
          path={PATH.mentors}
          exact={true}
          component={PageMentors} />
        <Route
          path={PATH.regionalCoordinators}
          exact={true}
          component={PageRegionalCoordinators} />
        <Route
          path={PATH.medalist}
          exact={true}
          component={PageMedalists} />
        <Route
          path={PATH.confirmEmail}
          exact={true}
          component={EmailConfirm} />
        <Route
          path={PATH.centers}
          exact={true}
          component={PageCenters} />
        <Route
          path={PATH.smiDetail}
          exact={true}
          component={PageSMIDetail} />
        <Route
          path={PATH.parents}
          exact={true}
          component={PageParents} />
        <Route
          path={PATH.moderation}
          exact={true}
          component={PageModeration} />
        <Route
          path={PATH.contact}
          exact={true}
          component={PageContact} />
        <Route
          path={PATH.termsofuse}
          exact={true}
          component={PageTermsOfUse} />
        <Route
          path={PATH.media}
          exact={true}
          component={PageMedia} />
        <Route
          path={PATH.map}
          exact={true}
          component={PageMap} />
        <Route
          path={PATH.vidioguide}
          exact={true}
          component={PageVideoguide} />
        <Route
          path={PATH.cabinet}
          component={PageCabinet} />
        <Route
          path={PATH.notFound}
          exact={true}
          component={PageNotFound} />
        <Route
          path={PATH.forbidden}
          exact={true}
          component={PageForbidden} />
        <Route
          path={PATH.modalSuccessPasswordReset}
          exact={true}
          component={SuccessPasswordReset} />
        <Route
          path={PATH.rest}
          component={() => <Redirect to={PATH.notFound} />} />
      </Switch>
      <FooterDefault />
      {user.authenticated && (
        <Chat />
      )}
    </div>
  );
};

export default LayoutDefault;

