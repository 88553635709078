import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import { getContent } from '../../../api';
import LoadablePage from "../../../components/LoadablePage";
import {IStore} from "../../../store";

const PageAwards: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      data: () => getContent(`rewarding-page`),
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const data: any = loaded.data;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          data={data}/>
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageAwards;
