import React from 'react';
import { SOCKET_URL } from '../../api';
import { IChatAttachment } from './api';

interface IProps {
  attachment: IChatAttachment;
  senderName: string;
  extension: string;
}

const MessageVideo: React.FC<IProps> = (props) => {
  const {attachment, senderName, extension} = props;
  const path = `${SOCKET_URL}/${attachment.path}/${attachment.filename}`;

  return (
    <React.Fragment>
      <div className="video">
        <video width="150" height="150" autoPlay={false} controls={true}>
          <source src={path} type={`video/${extension}`} />
        </video>
      </div>
      <div className="text">
        <div className="sender-name">
          {senderName}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageVideo;
