import React, { useRef, useState } from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import Input from '../../components/Input';
import { USER_ROLES } from '../../helpers';
import { PATH } from '../../router';
import { ASetLocale, ASetUser } from '../../store';
import { login } from './api';
import { loginIin } from './api';

interface IProps {
}

interface IState {
  email: string;
  iin: string;
  password: string;
  typeOfLogin: string;
}

interface IErrors {
  [key: string]: string | null;
}

const initialState: IState = {
  email: '',
  iin: '',
  password: '',
  typeOfLogin: 'mail',
};

const initialErrors: IErrors = {};

const ModalLogin: React.FC<IProps> = (props) => {
  const {t, i18n} = useTranslation();
  const emailRef = useRef<HTMLInputElement>();
  const iinRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();

  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [pending, setPending] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().required(t('required_field')).email(t('incorrect_email_format')),
    password: yup.string().required(t('required_field'))
  });

  const validationSchemaIin = yup.object().shape({
    iin: yup.string().required(t('required_field')),
    password: yup.string().required(t('required_field'))
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {name, value} = e.target;

    if (value.length > 0 && !!errors[name]) {
      const tempErrors = {...errors};

      tempErrors[name] = null;
      delete tempErrors[name];

      setErrors({...tempErrors});
    }
    setState({
      ...state,
      [name]: value
    });
  };

  const switchTypeMail = () => {
    setState({
      ...state,
      typeOfLogin: 'mail',
    })
  }

  const switchTypeIin = () => {
    setState({
      ...state,
      typeOfLogin: 'iin',
    })
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.persist();

    // @ts-ignore
    const {name} = e.target;

    if (e.which === 13 || e.keyCode === 13) {
      if (name === 'email') {
        // @ts-ignore
        passwordRef.current.focus();
      }

      if (name === 'password') {
        onClickLogin();
      }
    }
  };

  const onClickLogin = () => {
    if(state.typeOfLogin === 'mail') {
      validationSchema.validate(state, {
        abortEarly: false
      })
      .then(() => {
        setErrors(initialErrors);
        setPending(true);

          login({
            email: state.email,
            password: state.password
          }).then(response => {
            setState({...initialState});
  
            cookie.save('token', response.api_token, {path: '/'});
            cookie.save('locale', response.locale, {path: '/'});
            dispatch(ASetLocale(response.locale));
            i18n.changeLanguage(response.locale);
            dispatch(ASetUser({
              authenticated: true,
              filled: response.filled,
              blocked: response.blocked,
              days_remaining_type: response.days_remaining_type,
              days_remaining: response.days_remaining,
              id: response.id,
              profile_filled_at: response.profile_filled_at,
              limited_access: response.limited_access,
              program_level_hours_remaining: response.program_level_hours_remaining,
              first_name: response.first_name,
              last_name: response.last_name,
              user_role_id: response.user_role_id,
              user_role: response.user_role,
              avatar: response.avatar,
              area_id: response.area_id,
              is_password_updated: !!response.is_password_updated,
              is_agree: !!response.is_agree,
              is_show_hint: !!response.is_show_hint,
              email: response.email,
              new_notification_count: response.new_notification_count,
              notification_count: response.notification_count,
              new_trophies_count: response.new_trophies_count ? response.new_trophies_count : 0,
              winner: response.member ? response.member.winner : null,
              program_level_id: response.member ? response.member.program_level_id : 0
            }));
  
            setPending(false);
  
            // @ts-ignore
            $('.modal-form-blk').css('display', 'none');
  
            // @ts-ignore
            $('.overlay').fadeOut('fast');
  
            if ([USER_ROLES.coordinator.id, USER_ROLES.expert.id].indexOf(response.user_role_id) > -1) {
              history.push(PATH.cabinet);
            } else {
              history.push(PATH.cabinet);
            }
          }).catch((error) => {
            const {response} = error;
            let errorMessage = t('error_default_message');
            
            if (response) {
              const {status, data} = response;
  
              if (status === 403) {
                errorMessage = data.message;
              }
  
              if (status === 422 && data.errors && data.errors.email && data.errors.email[0]) {
                errorMessage = data.errors.email[0];
              }
            }
            setErrors({
              // @ts-ignore
              email: errorMessage
            });
  
            setPending(false);
          });
      })
      .catch(yupErrors => {
        const errors = {};

        yupErrors.inner.forEach((item: ValidationError) => {
          // @ts-ignore
          errors[item.path] = item.message;
        });

        setErrors(errors);
      });
    }else{
      validationSchemaIin.validate(state, {
        abortEarly: false
      })
      .then(() => {
        setErrors(initialErrors);
        setPending(true);

          loginIin({
            iin: state.iin,
            password: state.password
          }).then(response => {
            setState({...initialState});
  
            cookie.save('token', response.api_token, {path: '/'});
            cookie.save('locale', response.locale, {path: '/'});
            dispatch(ASetLocale(response.locale));
  
            dispatch(ASetUser({
              authenticated: true,
              filled: response.filled,
              blocked: response.blocked,
              days_remaining_type: response.days_remaining_type,
              days_remaining: response.days_remaining,
              profile_filled_at: response.profile_filled_at,
              limited_access: response.limited_access,
              id: response.id,
              program_level_hours_remaining: response.program_level_hours_remaining,
              first_name: response.first_name,
              last_name: response.last_name,
              user_role_id: response.user_role_id,
              user_role: response.user_role,
              avatar: response.avatar,
              area_id: response.area_id,
              is_password_updated: !!response.is_password_updated,
              is_agree: !!response.is_agree,
              is_show_hint: !!response.is_show_hint,
              email: response.email,
              new_notification_count: response.new_notification_count,
              notification_count: response.notification_count,
              new_trophies_count: response.new_trophies_count ? response.new_trophies_count : 0,
              winner: response.member ? response.member.winner : null,
              program_level_id: response.member ? response.member.program_level_id : 0
            }));
  
            setPending(false);
  
            // @ts-ignore
            $('.modal-form-blk').css('display', 'none');
  
            // @ts-ignore
            $('.overlay').fadeOut('fast');
  
            if ([USER_ROLES.coordinator.id, USER_ROLES.expert.id].indexOf(response.user_role_id) > -1) {
              history.push(PATH.cabinet);
            } else {
              history.push(PATH.cabinet);
            }
          }).catch((error) => {
            const {response} = error;
            let errorMessage = t('error_default_message');
  
            if (response) {
              const {status, data} = response;
  
              if (status === 403) {
                errorMessage = data.message;
              }
              if (status === 422) {
                errorMessage = data.errors.email[0]
              }
  
              // if (status === 422 && !!data.errors.email) {
              //   errorMessage = `${t('email_incorrect')} ${t('check_the_correctness_of_the_entered_data')}`;
              // }
            }
            setErrors({
              // @ts-ignore
              iin: errorMessage
            });
  
            setPending(false);
          });
      })
      .catch(yupErrors => {
        const errors = {};

        yupErrors.inner.forEach((item: ValidationError) => {
          // @ts-ignore
          errors[item.path] = item.message;
        });

        setErrors(errors);
      });
    }
  };

  return (
    <div className="modal-form-blk auth-form" id="authorize">
      <div className="in-blk">
        <a href="#" className="close-modal" />
        <div className="row">
          <div className="col-sm-7">
            <form className="form-simple">
              <h4 className="header-title">{t('enter_the_data_to_log_in')}</h4>
              <div className="login-types">
                <p className={`${state.typeOfLogin === 'mail' ? 'active' : ''} type-of-login`} onClick={switchTypeMail}>{t('login-type-email')}</p>
                <p className={`${state.typeOfLogin === 'iin' ? 'active' : ''} type-of-login`} onClick={switchTypeIin}>{t('login-type-iin')}</p>
              </div>
              {
                state.typeOfLogin === 'mail' ?
                <Input
                  required={true}
                  error={errors.email}
                  label={t('email')}
                  value={state.email}
                  name="email"
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  ref={emailRef}
                  type="text" />
                :
                <Input
                  required={false}
                  error={errors.iin}
                  label={t('iin')}
                  value={state.iin}
                  name="iin"
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  ref={iinRef}
                  type="number" />
              }
              <Input
                required={true}
                error={errors.password}
                label={t('password')}
                value={state.password}
                name="password"
                onChange={onChange}
                onKeyPress={onKeyPress}
                ref={passwordRef}
                type="password" />
              <div className="form-group">
                <label className="checkcont">{t('remember_me')}
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="btn-blk row">
                <div className="col-sm-6 text-left">
                  <button
                    style={pending ? {
                      backgroundColor: 'rgba(0,0,0,0.3)',
                      borderColor: 'rgba(0,0,0,0.3)'
                    } : {}}
                    disabled={pending}
                    className="btn btn-primary"
                    onClick={onClickLogin} type="button">
                    {t('login')}
                  </button>
                </div>
                <div className="col-sm-6 text-right link">
                  <a href="#forgot" className="show-modal" data-target="forgot">{t('forgot_password')}</a>
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-5 img">

          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogin;
