import instance from "../../../../api";
import { INotification } from "../../../../interfaces";


export interface IResponse {
    data: INotification[];
    meta: {
        current_page: number;
        last_page: number;
    }
}

export const getNotifications = (params: any): Promise<IResponse> => {
    return instance().get('/v1/notifications', {
        params,
    }).then(response => response.data);
}

export const readAllNotifications = (): Promise<IResponse> => {
    return instance().post('/v1/notifications/read-all').then(response => response.data);
}

export const getMember = (slug: string, id: number) => {
    return instance().get(`/v1/${slug}/members/${id}`).then(response => { return { ...response.data.data, ...response.data.data.user } });
  };
  
