import instance from "../../api";
import { INewsFilterParams } from "../News/api";

interface IGetNewsParams extends INewsFilterParams {
  locale: string;
}

export const getSearch = (params: any): Promise<{
  meta: {
    total: number;
    per_page: number;
  },
  data: any[]
}> => {
  return instance(false).get('/v1/global-search',{
    params,
  }).then(response => response.data);
};
