import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from '../../router';
import { IStore } from '../../store';

interface IProps {
}

interface IRoute {
  path: string;
  name: string;
  exact?: boolean;
}

const NavigationDefault: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const {user, locale, counts} = useSelector((store: IStore) => ({
    user: store.user,
    locale: store.locale,
    counts: store.counts,
  }));
  const location = useLocation();
  const routes: IRoute[] = [
    {path: PATH.home, name: t('about_project'), exact: true},
    {path: PATH.program, name: t('program')},
    {path: PATH.rules, name: t('rules')},
    {path: PATH.news, name: t('news')}
  ];

  routes.push({path: PATH.parents, name: t('for_parents')});

  user?.authenticated
    ? routes.push({path: PATH.cabinetFAQ, name: t('faq_and_video_lessons')})
    : routes.push({path: PATH.faq, name: t('faq')});

  routes.push({path: PATH.medalist, name: t('medalist')});
  routes.push({path: PATH.mentors, name: t('mentors')});
  routes.push({path: PATH.regionalCoordinators, name: t('regionalCoordinators')});
  routes.push({path: PATH.centers, name: t('program_centers')});

  const getActiveClassName = (route: IRoute): string => {
    const activeClassName = 'active';

    if (!!route.exact && location.pathname === route.path) {
      return activeClassName;
    }

    if (!route.exact && location.pathname.indexOf(route.path) > -1) {
      return activeClassName;
    }

    return '';
  };

  let days = 0;
  if (counts.refused_task_sent_at) {
    days = moment().diff(counts.refused_task_sent_at, 'days') + 1;
  }

  return (
    <nav className="main-menu">
      <div className="cnt-blk">
        <div className="row">
          <div className="col-sm-9">
            <ul>
              {routes.map((route, index) => (
                <li
                  key={index}
                  className={getActiveClassName(route)}>
                  <Link to={route.path} className={`color-${index + 1}`}>{route.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-sm-3 text-right social">

            <a href="https://www.fpp.kz" target={'_blank'} className={'fpp' + locale} />
            <a href="https://www.instagram.com/elbasy_academy/" target={'_blank'} className="inst " />
            <a href="https://www.facebook.com/ElbasyMedali" target={'_blank'} className="fb" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationDefault;
