import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';

interface IProps {
  locale: string;
}

const PageSuccessPasswordReset: React.FC<IProps> = ({locale, ...props}) => {
  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      return (
        <Page
          locale={locale}
          {...loadedProps}
        />
      );
    }
  });

  return <LoadableMap {...props} />;
};

const mapStateToProps = (store: IStore) => ({
  locale: store.locale
});

export default connect(mapStateToProps, null)(PageSuccessPasswordReset);
