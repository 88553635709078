import React from 'react';
import { SOCKET_URL } from '../../api';
import { downloadFile } from '../../helpers';
import { IChatAttachment } from './api';

interface IProps {
  attachment: IChatAttachment;
  senderName: string
}

const MessageImage: React.FC<IProps> = (props) => {
  const {attachment, senderName} = props;
  const path = `${SOCKET_URL}/${attachment.path}/${attachment.filename}`;

  const onClickDownloadFile = (attachment: IChatAttachment) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    downloadFile(path, attachment.filename);
  };

  return (
    <React.Fragment>
      <a
        href="#"
        className="image"
        onClick={onClickDownloadFile(attachment)}
        style={{backgroundImage: `url(${path})`}} />
      <div className="text">
        <div className="sender-name">
          {senderName}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageImage;
