import instance from "../../api";
import {
  IArea,
  ICountry,
  IProgramLevel,
  IGender,
  ICity,
  IEducationCategory,
  IEducationOrganization,
  IDirection,
  IEducationCourse,
} from "../../interfaces";

export const getCountries = (): Promise<ICountry[]> => {
  return instance(false)
    .get("/v1/countries")
    .then((response) => response.data.data);
};

export const getAreas = (countryId: number): Promise<IArea[]> => {
  return instance(false)
    .get(`/v1/countries/${countryId}/areas`)
    .then((response) => response.data.data);
};

export const getDistricts = (areaId: number): Promise<IArea[]> => {
  return instance(false)
      .get(`/v1/areas/${areaId}/districts`)
      .then((response) => response.data.data);
};

export const getCityById = (areaId: number, districtId: number): Promise<IArea[]> => {
  return instance(false)
      .get(`/v1/cities?area_id=${areaId}&district_id=${districtId}`)
      .then((response) => response.data.data);
};

export const getCities = (params = {}): Promise<ICity[]> => {
  return instance(false)
    .get("/v1/cities", {
      params,
    })
    .then((response) => response.data.data);
};

export const getGenders = (): Promise<IGender[]> => {
  return instance(false)
    .get("/v1/gender")
    .then((response) => response.data);
};

export const getNationalities = (): Promise<any> => {
  return instance(false)
      .get("/v1/nationalities")
      .then(response => response.data);
}

export const getProgramLevels = (): Promise<IProgramLevel[]> => {
  return instance(false)
    .get("/v1/program-levels")
    .then((response) => response.data.data);
};

export const getEducationCategories = (): Promise<IEducationCategory[]> => {
  return instance(false)
    .get("/v1/education-categories")
    .then((response) => response.data.data);
};

export const getEducationOrganizations = (): Promise<
  IEducationOrganization[]
> => {
  return instance(false)
    .get("/v1/educations")
    .then((response) => response.data.data);
};

export const getDirections = (): Promise<IDirection[]> => {
  return instance(false)
    .get("/v1/directions")
    .then((response) => response.data.data);
};

export const getProgramDirections = (
  program_level_id: any
): Promise<IDirection[]> => {
  return instance(false)
    .get(`/v1/directions/${program_level_id}`)
    .then((response) => response.data.data);
};

export const createRequest = (data: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key: string) => {
    if (key !== "files") {
      formData.append(key, data[key]);
    } else {
      data[key].forEach((file: File) => {
        formData.append("files[]", file);
      });
    }
  });

  return instance(false)
    .post("/v1/members/participate", formData)
    .then((response) => response.data.data);
};

export const getEducationCourses = (): Promise<IEducationCourse[]> => {
  return instance(false)
    .get("/v1/education-courses")
    .then((response) => response.data.data);
};

export const createRequestCenter = (data: any): Promise<any> => {
  const formData = new FormData();

  Object.keys(data).forEach((key: string) => {
    if (key === "files") {
      data[key].forEach((file: File) => {
        formData.append("files[]", file);
      });
    } else if (key === "images") {
      data[key].forEach((file: File) => {
        formData.append("images[]", file);
      });
    } else if (key === "links") {
      data[key].forEach((file: File) => {
        formData.append("links[]", file);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return instance(false)
    .post("/v1/program-centers/participate", formData)
    .then((response) => response.data);
};
