import instance from "../../api";
import { ILoginResponse } from "../../interfaces";

export interface ILoginData {
  email: string;
  password: string;
}

export interface ILoginDataIin {
  iin: string;
  password: string;
}

export const login = (data: ILoginData): Promise<ILoginResponse> => {
  return instance(false).post('/v1/login', data).then(response => response.data.data);
};

export const loginIin = (data: ILoginDataIin): Promise<ILoginResponse> => {
  return instance(false).post('/v1/login', data).then(response => response.data.data);
};
