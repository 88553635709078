import qs from 'qs';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from './api';
import { ISelectOption } from './components/Select';
import { IAvatar, ILoginResponse } from './interfaces';

export const createArrayOfNumbers = (from: number, to: number): number[] => {
  const numbers = [];

  for (let i = from; i < to; i++) {
    numbers.push(i);
  }

  return numbers;
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLocale = (): string => {
  return cookie.load('locale') || 'kz';
};

export const getVision = (): boolean => {
  return !!cookie.load('vision');
};

export const maskNumberString = (value: string, mask: string = '(###) ### ## ##') => {
  let i = 0;
  let lastReplacedIndex = -1;
  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= value.length) {
      return '#';
    }
    lastReplacedIndex = j;
    return value[i++];
  });

  return filledMask.substring(0, lastReplacedIndex + 1);
};

export const clearNumberString = (number: string) => {
  return number.replace(/\D/g, '');
};

export const monthName = (index: number, locale: string): string => {
  const names: {
    [key: string]: string[]
  } = {
    ru: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
    kz: ['қаңтар', 'ақпан', 'наурыз', 'сәуір', 'мамыр', 'маусым', 'шілде', 'тамыз', 'қыркүйек', 'қазан', 'қараша', 'желтоқсан'],
    en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  };

  if(locale === null) {
    locale = 'ru'
  }

  console.log(locale)

  return names[locale] ? names[locale][index] : names['ru'][index];
};

export const getWeekShortNames = (index: number, locale: string): string => {
  const names: {
    [key: string]: string[]
  } = {
    ru: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
    kz: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
    en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  };

  if(!locale) {
    locale = 'ru'
  }

  return names[locale][index];
};

export const getAgeString = (age: number): string => {
  const ages = {
    ru: '',
    kz: 'жас',
    en: 'years'
  };

  let count = age % 100;

  if (count >= 5 && count <= 20) {
    ages.ru = 'лет';
  } else {
    count = count % 10;
    if (count === 1) {
      ages.ru = 'год';
    } else if (count >= 2 && count <= 4) {
      ages.ru = 'года';
    } else {
      ages.ru = 'лет';
    }
  }

  // @ts-ignore
  return `${age} ${ages[getLocale()]}`;
};

export const getGender = (gender: number): string => {
  const genders = {
    ru: [
      'мужчина', 'женщина'
    ],
    kz: [
      'ер', 'әйел'
    ],
    en: [
      'male', 'female'
    ]
  };

  // @ts-ignore
  return genders[getLocale()][gender - 1];
};

export const formatNumberString = (number: number, text_forms: string[]): string => {
  const n = Math.abs(number) % 100;

  let n1 = n % 10;

  if (n > 10 && n < 20) {
    return `${number} ${text_forms[2]}`;
  }
  if (n1 > 1 && n1 < 5) {
    return `${number} ${text_forms[1]}`;
  }
  if (n1 === 1) {
    return `${number} ${text_forms[0]}`;
  }

  return `${number} ${text_forms[2]}`;
};

export const clearPhoneNumber = (phone: string) => {
  if (!phone) {
    return "";
  }

  phone = phone.replace(/[^0-9]/g, '');

  if (phone.length === 10) {
    return phone;
  }

  if (phone.length === 11) {
    return phone.substring(1);
  }

  return phone;
};

export const USER_ROLES = {
  mio: {
    id: 8,
    slug: 'mio'
  },
  coordinator: {
    id: 5,
    slug: 'regional-coordinator'
  },
  expert: {
    id: 4,
    slug: 'expert'
  },
  mentor: {
    id: 3,
    slug: 'mentor'
  },
  member: {
    id: 1,
    slug: 'member'
  }
};

export const getUserRole = (userRoleId: number) => {
  switch (userRoleId) {
    case USER_ROLES.mio.id:
      return USER_ROLES.mio.slug;
    case USER_ROLES.coordinator.id:
      return USER_ROLES.coordinator.slug;
    case USER_ROLES.expert.id:
      return USER_ROLES.expert.slug;
    case USER_ROLES.mentor.id:
      return USER_ROLES.mentor.slug;
    case USER_ROLES.member.id:
      return USER_ROLES.member.slug;
    default:
      return '';
  }
};

export const getColorDirection = (id: number): string => {
  const names = ['#60B05E', '#C7368C', '#2A80BE', '#FBBB0E', '#E64256', '#01B8DC', '#725fa6'];
  return names[id - 1] || '';
};

export const getStatusName = (id: number, locale: string): string => {
  const names: {
    [key: string]: string[]
  } = {
    ru: ['Не начато', 'Запрос на перенос', 'Перенесен', 'Планируется начать', 'На подтверждении', 'Подтверждён', 'Отклонен', 'Не выполнено в срок', '', '', '', 'Не выполнено'],
    kz: ['Басталмаған', 'Тасымалдауға сұрау салу', 'Ауыстырылды', 'Бастау жоспарлануда', 'Растауда', 'Расталды', 'Қабылданбаған', 'Мерзімі аяқталды', '', '', '', 'Жасалмады'],
    en: ['Not started', 'Transfer request', 'Postponed', 'Planned to start', 'On confirmation', 'Confirmed', 'Rejected', 'Expired', '', '', '', 'Not performed']
  };

  return names[locale][id] || '';
};

export const getStatuses = (locale: string): string[] => {
  const names: {
    [key: string]: string[]
  } = {
    ru: ['Не начато', 'Запрос на перенос', 'Перенесен', 'Планируется начать', 'На подтверждении', 'Подтверждён', 'Отклонен', 'Просрочен'],
    kz: ['Басталмаған', 'Тасымалдауға сұрау салу', 'Ауыстырылды', 'Бастау жоспарлануда', 'Растауда', 'Расталды', 'Қабылданбаған', 'Мерзімі аяқталды'],
    en: ['Not started', 'Transfer request', 'Postponed', 'Planned to start', 'On confirmation', 'Confirmed', 'Rejected', 'Expired']
  };

  return names[locale];
};

export const getNotificationsTypes = (locale: string, role: number = 3): ISelectOption[] => {
  const names: {
    [key: string]: ISelectOption[]
  } = role === 3 ? {
    ru: [
      {value: 13, label: 'Задание на удаление'},
      {value: 11, label: 'Запрос на отклонение задания'},
      {value: 3, label: 'Задание на подтверждении'},
      {value: 2, label: 'Запрос на перенос'},
      {value: 1, label: 'Сообщение от Участника'},
      {value: 16, label: 'Новые заявки'},
      {value: 18, label: 'Контрольные отчеты'}
    ],
    kz: [
      {value: 13, label: 'Жойылатын тапсырмалар'},
      {value: 11, label: 'Тапсырманы кeрi қайтару сұранымдары'},
      {value: 3, label: 'Бекiтiлуге жiберiлген тапсырмалар'},
      {value: 2, label: 'Тапсырманы ауыстыру сұранымдары'},
      {value: 1, label: 'Қатысушыдан келген хабарлама'},
      {value: 16, label: 'Жаңа өтінімдер'},
      {value: 18, label: 'Бақылау есебi'}
    ],
    en: [
      {value: 13, label: 'Jobs to delete'},
      {value: 11, label: 'Request to reject a job'},
      {value: 3, label: 'On confirmation tasks'},
      {value: 2, label: 'Job transfer request'},
      {value: 1, label: 'Message from participant'},
      {value: 16, label: 'New applications'},
      {value: 18, label: 'Control reports'}
    ]
  } : {
    ru: [
      {value: 12, label: 'Задание на удаление'},
      {value: 11, label: 'Запрос на отклонение задания'},
      {value: 8, label: 'Новые заявки'},
      {value: 2, label: 'Запрос на перенос'},
      {value: 18, label: 'Контрольные отчеты'}
    ],
    kz: [
      {value: 12, label: 'Жойылатын тапсырмалар'},
      {value: 11, label: 'Тапсырманы кeрi қайтару сұранымдары'},
      {value: 8, label: 'Жаңа өтінімдер'},
      {value: 2, label: 'Тапсырманы ауыстыру сұранымдары'},
      {value: 18, label: 'Бақылау есебi'}
    ],
    en: [
      {value: 12, label: 'Jobs to delete'},
      {value: 11, label: 'Request to reject a job'},
      {value: 8, label: 'New applications'},
      {value: 2, label: 'Job transfer request'},
      {value: 18, label: 'Control reports'}
    ],
  };

  return names[locale];
};

export const getUserThumb = (avatar: IAvatar | null, thumb: boolean = true, backgroundURL: boolean = true): string | null => {
  if (!avatar) {
    return null;
  }

  const url = `${BASE_URL}/${avatar.path}/${thumb ? avatar.thumb : avatar.name}`;

  return backgroundURL ? `url(${url})` : url;
};

export const getChatAvatar = (avatar: IAvatar | null, backgroundURL: boolean = true): string | null => {
  if (!avatar) {
    return null;
  }

  const url = `${avatar.host!}/${avatar.path}/${avatar.filename!}`;

  return backgroundURL ? `url(${url})` : url;
};

export const getTaskName = (task: any, locale: string): string => {
  let text = task.sub_category ? task.sub_category[`title_${locale}`] : task.other_category;

  switch (task.direction_id) {
    case 1:
      if (task.standard) {
        text = task.standard[`title_${locale}`];

        if (task.standard.quantity && task.standard.unit_id) {
          if (task.standard.parent)
            text = `${task.standard.parent[`title_${locale}`]} | ${text}, ${task.standard.quantity} ${task.standard.unit_id}`;
          else
            text = `${text}, ${task.standard.quantity} ${task.standard.unit_id}`;
        }
      }

      return text;
    case 3:
      if (task.book) {
        text = task.book[`title_${locale}`];
      }

      return text;
    case 4:
      if (task.category_id === 294) {
        if (task.book) {
          text = task.book[`title_${locale}`];
        } else {
          text = '';
        }
      }

      return text;
    default:
      return text;
  }
};

export const getTaskDirectionParticipate = (task: any, locale: string): string => {
  if (task.is_direct_participation == 1) {
    if (locale == 'ru')
      return 'Прямое участие';
    else
      return 'Тікелей қатысу';

  } else {
    return '';
  }
};

export const getTaskCategoryName = (task: any, locale: string): string => {
  let text = !!task.category ? task.category[`title_${locale}`] : '';

  switch (task.direction_id) {
    case 1:
      if (!!task.standard) {
        text = '';
      }

      return text;
    default:
      return text;
  }
};

export const getPageCount = (task: any, locale: string): string => {
  if (task && task.aims && task.aims[0].count_pages && task.direction_id === 3) {
    switch (locale) {
      case 'en':
        return `| Read ${task.aims[0].count_pages} pages`;
      case 'kz':
        return `| ${task.aims[0].count_pages} бет оқу`;
      default:
        return `| Прочитать ${task.aims[0].count_pages} стр.`;
    }
  } else {
    return '';
  }
};

export const getUserRequiredFields = (profile: ILoginResponse) => {
  const data: any = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    birthday: profile.birthday,
    iin: profile.iin,
    gender: profile.gender,
    phone: profile.phone,
    area_id: profile.area_id,
    education_category_id:
      profile.education && profile.education[0] && profile.education[0].education_category
        ? profile.education[0].education_category.id
        : 1,
    education_course_id:
      profile.education && profile.education[0] && profile.education[0].education_course
        ? profile.education[0].education_course.id
        : 1,
    // program_level_id: profile.program_level_id, //
    street: profile.street,
    house: profile.house,
    email: profile.email
  };

  if (!profile.other_city) {
    data.city_id = profile.city_id;
  } else {
    data.other_city = profile.other_city;
  }

  if (!profile.other_education) {
    data.education_id = profile.education && profile.education[0] && profile.education[0].education
      ? profile.education[0].education.id
      : 1;
  } else {
    data.other_education = profile.other_education;
  }
  data.user_education_id = profile.education && profile.education[0] ? profile.education[0].id
    : 1;

  data.other_education_area = profile.education && profile.education[0] && profile.education[0].other_education_area
    ? profile.education[0].other_education_area
    : '1';
  data.other_education_city = profile.education && profile.education[0] && profile.education[0].other_education_city
    ? profile.education[0].other_education_city
    : '1';

  return data;
};

export const setParams = (params: any) => {
  window.history.replaceState('', '', `${window.location.origin}${window.location.pathname}${qs.stringify(params, {
    addQueryPrefix: true
  })}`);
};

export const makeCaptchaString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  let result = '';

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const acceptedVideoFormats = ['3gp', 'webm', 'ogv', 'mov', 'mp4', 'avi', 'mkv', 'wmv'];

export const downloadFile = (url: string, name: string) => {
  const link = document.createElement('a');

  link.setAttribute('download', name);
  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const countWords = (str: string, count: number = 0) => {
  var matches = str.match(/[a-zA-Z0-9А-Яа-я\’\'t]+|[a-zA-Z0-9А-Яа-я\’\'s]+/gi);
  return matches ? matches.length : 0;
};

export const clean = (arr: string[]) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == '') {
      arr.splice(i, 1);
      i--;
    }
  }
  return arr;
};

export const getShortName = (name: string): string => {
  if (!name) {
    return '';
  }

  let returnName = '';
  const nameStrings = name.split(' ');

  nameStrings.forEach((str, i) => {
    if (i >= 2) return;
    returnName = `${returnName} ${str.charAt(0)}`;
  });

  return returnName.trim().toUpperCase();
};

export const getRandomColor = (fullName?: string): string => {
  const colors = ['#4EBA68', '#C33F97', '#2B82BE', '#FDB927', '#EC4C5E', '#16BEDD', '#775E98', '#08B2B1'];

  if (!!fullName) {
    let fullNameLength: number = parseInt(`${fullName.trim().split('').length / 2}`);

    while (fullNameLength > 8) {
      fullNameLength -= 1;
    }

    return colors[fullNameLength - 1];
  }

  return colors[Math.floor(Math.random() * colors.length)];
};

export const getUrlFromString = (str: string): string => {
  const urlRegExp = new RegExp('([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+');

  if (!urlRegExp.test(str)) {
    return '';
  }

  const matchStr = str.match(urlRegExp);

  return matchStr ? matchStr[0] : '';
};

export const MESSAGE_ATTACHMENTS = {
  DOC: 1,
  IMAGE: 2,
  VIDEO: 3
};

export const setMetaKeywords = (keywords: string) => {
  const el: HTMLMetaElement | null = document.querySelector('meta[name=\'keywords\']');

  if (el) {
    el.setAttribute('content', keywords);
  }
};

export const setMetaDescription = (desc: string) => {
  const el: HTMLMetaElement | null = document.querySelector('meta[name=\'description\']');

  if (el) {
    el.setAttribute('content', desc);
  }
};

export const getFilePath = (item: any) => {
  if (!!item.is_archive && !!item.archive_full_url) {
    console.log('item', item.archive_full_url);
    return `${item.archive_full_url}`;
  } else {
    console.log('item2');
    return `${BASE_URL}/${item.path}/${item.name}`;
  }

}

export const useWaysList = () => {
  const {t} = useTranslation();

  return [{
    'title': t('sports_and_physical_development'),
    'text': t('sports_and_physical_development_info'),
    'image': 'w1',
    'path': 'sport'
  },
    {
      'title': t('talents_and_skills'),
      'text': t('talents_and_skills_info'),
      'image': 'w2',
      'path': 'talant'
    },
    {
      'title': t('reading_of_books'),
      'text': t('reading_of_books_info'),
      'image': 'w3',
      'path': 'book'
    },
    {
      'title': t('national_heritage'),
      'text': t('national_heritage_info'),
      'image': 'w4',
      'path': 'national'
    },
    {
      'title': t('volunteering'),
      'text': t('volunteering_info'),
      'image': 'w5',
      'path': 'volunteer'
    },
    {
      'title': t('hiking_trips'),
      'text': t('hiking_trips_info_2'),
      'image': 'w6',
      'path': 'tourist'
    },
    {
      'title': t('labor_practice'),
      'text': t('labor_practice_info'),
      'image': 'w7',
      'path': 'practice'
    }];
};
