import React from 'react';
import {useLocation} from "react-router-dom";
import instance from "../../api";

export const EmailConfirm = () => {
    const location = useLocation();

    const submit = () => {
        const search = location.search;
        if (search.split('=')[0] === '?token' && search.split('=')[1]) {
            return instance(false).get(`/v1/email/${search.split('=')[1]}/confirm`,)
                .catch(() => alert('Ошибка'));
        }
    }

    const goHome = () => {
        window.location.replace('/');
    };

    return (
        <section className="register-form-section content-blk">
            <div className="cnt-blk">
                <h2 className="page-title">Подтверждение электронного адреса</h2>
                <form className="form-simple">
                    <div className="row">
                        <div className="col-sm-6 form-responsive-col">
                            <div className="form-section-blk">
                                <div className="hint">
                                    Нажав кнопку ниже, Вы подтверждаете, что указанный e-mail адрес в заявке принадлежит
                                    Вам.
                                </div>
                                <div className="row" data-stage="6" id="step-6">
                                    <div className="col-sm-12 text-center">
                                        <a className="btn btn-primary show-modal"
                                           onClick={submit} style={{ color: 'white' }}
                                           data-target="success-register">Подтвердить</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-form-blk auth-form success-register" id="success-register">
                <div className="in-blk">
                    <a href="#" className="close-modal"></a>
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="txt">
                                <div className="i"></div>
                                <h4 className="header-title">Спасибо! Вы подтвердили ваш электронный адрес, ваша заявка
                                    на рассмотрении. Мы свяжемся с вами в ближайшее время</h4>
                                <a className="btn btn-primary" onClick={goHome} style={{color: 'white'}}>Вернуться на главную</a>
                            </div>
                        </div>
                        <div className="col-sm-5 img">

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
