import instance from "../../../../api";

/* export const getLog = (slug: string, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/registries`, {
    params: {
      ...params
    },
  }).then(response => response.data);
} */

export const deleteTask = (slug: string, memberId: number, taskId: number): Promise<any> => {
  return instance().delete(`/v1/${slug}/members/${memberId}/tasks/${taskId}`).then(response => response.data);
}

export const deleteTaskMentor = (slug: string, taskId: number): Promise<any> => {
  return instance().post(`/v1/${slug}/tasks/${taskId}/request-to-delete`).then(response => response.data);
}

export const getLogMembers = (slug: string, params: any): Promise<any> => {
  delete params.status_id
  return instance().get(`/v1/${slug}/members?status_id=1`, {
    params: {
      ...params
    },
  }).then(response => response.data);
}

export const getLogMemberTasks = (slug: string, taskId: number, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/members/${taskId}/tasks`, {
    params: {
      ...params
    },
  }).then(response => response.data);
}

export const getLogMentors = (slug: string, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/mentors/statistics?type=confirmed&is_manager=0`, {
    params: {
      ...params
    },
  }).then(response => response.data);
}

export const getLogMembersByMentor = (slug: string, memberId: number, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/members?is_manager=0&mentor_id=${memberId}`, {
    params: {
      ...params
    },
  }).then(response => response.data);
}

export const getLogMentorsTasks = (slug: string, taskId: number, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/members/${taskId}/tasks`, {
    params: {
      ...params
    },
  }).then(response => response.data);
}

export const generateUsersExcel = (slug: string, params: any): Promise<any> => {
  return instance().get(`/v1/${slug}/tasks/store-excel`, {
    params: {
      ...params
    }
  });
};

export const downloadUsersExcel = (slug: string): Promise<Blob> => {
  return instance().get(`/v1/${slug}/tasks/download-excel`, {
    responseType: 'blob'
  }).then(response => response.data);
};

