import React from 'react';
import Loadable from 'react-loadable';
import {useSelector} from "react-redux";
import { getContent } from '../../api';
import LoadablePage from "../../components/LoadablePage";
import {IStore} from "../../store";
import {getNews} from "../News/api";

const PageHome: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const vision = useSelector((state: IStore) => state.vision);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      news: () => getNews({
        locale,
        page: 1,
        sort_by: 'date_desc'
      }).then(response => response.data),
      banner: () => getContent(`connect-block`),
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const banner: any = loaded.banner;

      return (
        <Page
          {...loadedProps}
          banner= {banner}
          news={loaded.news}
          locale={locale}
          mapUrl={`../images/map_${locale}_${vision}.svg`}/>
      );
    },
  });

  return <LoadableMap {...props} />;
};

export default PageHome;
