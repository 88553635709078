import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { match as IRRDMatch, Redirect, useRouteMatch } from 'react-router-dom';
import { getContent } from '../../../api';
import LoadablePage from '../../../components/LoadablePage';
import { PATH } from '../../../router';
import { IStore } from '../../../store';

interface IMatch extends IRRDMatch {
  params: {
    type?: string;
  };
}

const PageWays: React.FC = (props) => {
  const match: IMatch = useRouteMatch();
  const {type} = match.params;
  const locale = useSelector((state: IStore) => state.locale);

  if (!type || type === ':type') {
    return <Redirect to={PATH.generate(PATH.ways, 'sport')} />;
  }

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import('./page'),
      data: () => getContent(`${type}-direction-page`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const data: any = loaded.data;

      return (
        <Page
          {...loadedProps}
          type={type}
          locale={locale}
          data={data} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageWays;
