import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { getContent } from '../../api';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';

const PageProgram: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);

  const LoadableMap = Loadable.Map({
    loader: {
      page: () => import(`./page`),
      banner: () => getContent(`program-description-block`),
      levels_block: () => getContent(`levels-block`),
      directions_block: () => getContent(`directions-block`),
      rewarding_block: () => getContent(`rewarding-block`)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          banner={loaded.banner}
          levels_block={loaded.levels_block}
          directions_block={loaded.directions_block}
          rewarding_block={loaded.rewarding_block} />
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageProgram;
