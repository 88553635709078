import classNames from 'classnames';
import { format } from 'date-fns';
import Picker from 'emoji-picker-react';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { getChatAvatar, getRandomColor, getShortName } from '../../helpers';
import { IAvatar } from '../../interfaces';
import { getDialogMessages, IChatDialogMessageReponse, IChatSettings, sendFileToChat } from './api';
import ChatGroupForm from './ChatGroupForm';
import ChatProfile from './ChatProfile';
import GroupAddForm from './GroupAddForm';
import { CONSTANTS, IChatUserInfo, socket } from './index';
import Message from './Message';
import { IDialog } from './reducer';
import {differenceInSeconds} from 'date-fns';

const EmojiContainer: React.FC<{
  onBlur: () => void
}> = (props) => {
  const emojiContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (emojiContainer.current) {
      emojiContainer.current.focus();
    }
  }, [emojiContainer.current]);

  return (
    <div
      ref={emojiContainer}
      // @ts-ignore
      tabIndex="0"
      style={{
        outline: 'none',
        position: 'absolute',
        bottom: 'calc(100% + 16px)',
        right: 16
      }}
      onBlur={(event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          props.onBlur();
        }
      }}>
      {props.children}
    </div>
  );
};

interface IChatProps {
  dialog: IDialog;
  closeDialog: () => void;
  onlineUsers: number[],
  availableUsers: IChatUserInfo[];
  onCreateGroupDialog: (dialog: IDialog) => void;
  t?: (key: string) => string;
}

export interface IChatMember {
  avatar: IAvatar | null;
  email: string;
  phone: string;
  user_id: number;
  username: string;
  _id: string;
}

interface IChatState {
  isLoadMore: boolean,
  hasMore: boolean,
  messages: IChatDialogMessageReponse[];
  message: string;
  typing: '';
  showAttachBlock: boolean;
  attachType: number;
  error: boolean;
  showChatGroupForm: boolean;
  showChatGroupAddForm: boolean;
  members: IChatMember[];
  showProfile: boolean;
  attachments: File[];
  chatGroupAddId: number;
  showEmoji: boolean;
  sending: boolean;
  searchText: string;
  settings: IChatSettings,
  currentUserId: number;
  hasPermission: boolean;
  errorTimeOut: boolean;
}

class Chat extends React.Component<IChatProps> {
  private mounted = true;
  private typing: NodeJS.Timeout | null = null;
  private errorTimeOut: NodeJS.Timeout | null = null;
  private attachInput = React.createRef<HTMLInputElement>();
  private chatRef = React.createRef<HTMLDivElement>();
  private emojiPicker = React.createRef<any>();

  state: IChatState = {
    errorTimeOut: false,
    isLoadMore: false,
    messages: [],
    message: '',
    hasMore: true,
    showProfile: false,
    showChatGroupForm: false,
    typing: '',
    showAttachBlock: false,
    attachType: 1,
    error: false,
    showChatGroupAddForm: false,
    members: [],
    attachments: [],
    chatGroupAddId: 0,
    hasPermission: false,
    showEmoji: false,
    sending: false,
    searchText: '',
    settings: {
      attach_files: false,
      attach_photo_video: false,
      send_message: false,
      timeout_of_sending: '',
      _id: ''
    },
    currentUserId: 0,
  };

  componentDidMount() {
    // this.getData();

    socket.on(CONSTANTS.GET_MESSAGE, (response: IChatDialogMessageReponse) => {
      if (response.chat_id === this.props.dialog.chat_id) {
        const {messages} = this.state;

        socket.emit('delivered message', {id: response._id});

        this.setState({
          messages: [...messages, response]
        }, () => {
          if (this.chatRef.current) {
            $(this.chatRef.current).scrollTop($(this.chatRef.current)[0].scrollHeight);
          }
        });
      }
    });

    socket.on('delivered message', (response: IChatDialogMessageReponse) => {
      if (response.chat_id === this.props.dialog.chat_id) {
        const {messages} = this.state;

        this.setState({
          messages: messages.map(message => {
            if (message._id === response._id) {
              message.statuses = response.statuses;
            }

            return message;
          })
        });
      }
    });

    socket.on(CONSTANTS.TYPING, (response: any) => {
      if (response.chat_id === this.props.dialog.chat_id) {
        if (this.typing) {
          clearTimeout(this.typing);
        }

        const typingUser: any = this.props.availableUsers.filter(user => user.user_id === response.user_id)[0];

        if (!!typingUser) {
          if (this.mounted) {
            this.setState({
              typing: typingUser.username
            }, () => {
              this.typing = setTimeout(() => {
                if (this.mounted) {
                  this.setState({
                    typing: ''
                  });
                }
              }, 4000);
            });
          }
        }
      }
    });

    socket.on(CONSTANTS.SETTINGS_UPDATED, (response: {
      settings: IChatSettings
    }) => {
      this.setState({
        settings: response.settings
      });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClickRetry = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      error: false
    }, () => {
      this.getData();
    });
  };

  onSearch = (searchText: string): void => {
    this.setState({
      searchText,
      messages: [],
      hasMore: true,
      showProfile: false,
      isLoadMore: false,
      error: false
    }, () => {
      this.getData();
    });
  };

  getData = (): void => {
    const stateMessages = [...this.state.messages];
    const params: any = {};

    if (stateMessages.length > 0) {
      params.date = stateMessages[0].created_at;
    }

    getDialogMessages(this.props.dialog._id, {
      ...params,
      q: this.state.searchText
    })
      .then(({chat, ...response}) => {
        let messages: any[] = [...stateMessages];
        let hasPermission = false;

        if (chat.type === 2) {
          hasPermission = chat.user_id === response.user.user_id;
        } else {
          const user1 = chat.members.filter(item => item.user_id === response.user.user_id)[0];
          const user2 = chat.members.filter(item => item.user_id !== response.user.user_id)[0];

          hasPermission = user1.role.id >= user2.role.id;
        }

        messages = [...response.messages.docs, ...messages];

        this.setState({
          messages,
          error: false,
          members: chat.members,
          hasMore: response.messages.hasNextPage,
          settings: chat.settings,
          currentUserId: response.user.user_id,
          isLoadMore: false,
          hasPermission
        }, () => {
          if (this.chatRef.current) {
            $(this.chatRef.current).scrollTop($(this.chatRef.current)[0].scrollHeight);
          }
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
          isLoadMore: false
        });
      });
  };

  groupMessages = (messages: IChatDialogMessageReponse[]): {
    [key: string]: IChatDialogMessageReponse[]
  } => {
    return messages.reduce((group: {
      [key: string]: IChatDialogMessageReponse[]
    }, item) => {
      const dateString: string = format(new Date(item.created_at), 'dd.MM.yyyy');

      (group[dateString] = group[dateString] || []).push(item);

      return group;
    }, {});
  };

  onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    e.persist();

    this.setState({
      message: e.target.value
    }, () => {
      const {dialog} = this.props;

      socket.emit(CONSTANTS.TYPING, {
        user_id: dialog.user_id,
        chat_id: dialog.chat_id
      });
    });
  };

  onClickEmoji = (e: any, emojiObject: any): void => {
    const {message} = this.state;

    this.setState({
      message: message + emojiObject.emoji
    }, () => {
      const {dialog} = this.props;

      socket.emit(CONSTANTS.TYPING, {
        user_id: dialog.user_id,
        chat_id: dialog.chat_id
      });
    });
  };

  onClickCreateGroup = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showChatGroupForm: true,
      chatGroupAddId: this.props.dialog.to_user_id
    });
  };

  onClickAddToGroup = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showChatGroupAddForm: true
    });
  };

  closeCreateGroup = (): void => {
    this.setState({
      showChatGroupForm: false
    });
  };

  closeCreateAddToGroup = (): void => {
    this.setState({
      showChatGroupAddForm: false,
      chatGroupAddId: 0
    });
  };

  onInputKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    e.persist();

    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();

      return this.sendMessage();
    }
  };

  onClickSendMessage = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.sendMessage();
  };

  onClickCloseDialog = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.props.closeDialog();
  };

  sendMessage = () => {
    const {dialog} = this.props;
    const {message, attachments, attachType, settings, messages, currentUserId} = this.state;

    if (message.length === 0 && attachments.length === 0) {
      return;
    }

    const currentUserMessages = messages.filter(item => item.from_id === currentUserId);

    if (currentUserMessages.length > 0 && parseInt(settings.timeout_of_sending) > 0) {
      if (differenceInSeconds(new Date(), new Date(currentUserMessages[currentUserMessages.length - 1].created_at)) < parseInt(settings.timeout_of_sending)) {
        return this.setState({
          errorTimeOut: true,
        }, () => {
          if (this.chatRef.current) {
            $(this.chatRef.current).scrollTop($(this.chatRef.current)[0].scrollHeight);
          }

          if (this.errorTimeOut) {
            clearTimeout(this.errorTimeOut);
          }

          this.errorTimeOut = setTimeout(() => {
            this.setState({
              errorTimeOut: false,
            })
          }, 3000);
        });
      }
    }

    this.setState({
      sending: true
    }, async () => {
      if (message.length !== 0) {
        socket.emit(CONSTANTS.SEND_MESSAGE, {
          chat_id: dialog.chat_id,
          message,
          from_id: dialog.user_id,
          to_id: dialog.to_user_id
        });
      }

      if (attachments.length > 0) {
        const response = await sendFileToChat(dialog.chat_id, attachType, attachments);

        socket.emit('send file message', {
          'attach': response,
          'chat_id': dialog.chat_id,
          'message': 'File',
          'from_id': dialog.user_id,
          'to_id': dialog.to_user_id
        });
      }

      this.setState({
        message: '',
        attachments: [],
        sending: false
      });
    });
  };

  showProfile = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showProfile: true
    });
  };

  hideProfile = (): void => {
    this.setState({
      showProfile: false
    });
  };

  //  TODO block user on rest
  onClickBlockUser = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    alert('TODO block user on rest');
  };

  showAttachBlock = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showAttachBlock: true
    });
  };

  hideAttachBlock = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showAttachBlock: false
    });
  };

  setAttachType = (attachType: number) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      showAttachBlock: false,
      attachType,
      attachments: []
    }, () => {
      this.attachInput.current!.click();
    });
  };

  getFileAccepts = (): string => {
    switch (this.state.attachType) {
      case 3:
        return 'video/*';
      case 2:
        return 'image/*';
      default:
        return '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.pdf';
    }
  };

  onFilesSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    const attachments: File[] = [];

    for (let i = 0; i < e.target.files!.length; i++) {
      attachments.push(e.target.files![i]);
    }

    return this.setState(prevState => ({
      // @ts-ignore
      attachments: [...prevState.attachments, ...attachments]
    }));
  };

  onGroupDialogCreated = (dialog: IDialog): void => {
    this.setState({
      showChatGroupForm: false
    }, () => {
      this.props.onCreateGroupDialog(dialog);
    });
  };

  onAddedToGroup = (): void => {

  };

  onClickAddToGroupProfile = (): void => {
    const {dialog} = this.props;

    this.setState({
      showProfile: false,
      showChatGroupAddForm: dialog.type === 2,
      showChatGroupForm: dialog.type === 1,
      chatGroupAddId: 0
    });
  };

  onClickDeleteAttachment = (index: number) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    const {attachments} = this.state;

    attachments.splice(index, 1);

    this.setState({
      attachments
    });
  };

  onClickDeleteAttachments = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    const {attachments} = this.state;

    while (attachments.length > 0) {
      attachments.pop();
    }

    this.setState({
      attachments
    });
  };

  getAttachmentURLObject = (attachment: File): string => {
    if (attachment.type.indexOf('image') > -1) {
      return `url(${URL.createObjectURL(attachment)})`;
    }

    return 'url()';
  };

  onClickShowEmoji = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    const {showEmoji} = this.state;

    this.setState({
      showEmoji: !showEmoji
    });
  };

  onLoadMore = (): void => {
    const {hasMore, isLoadMore} = this.state;

    if (!hasMore || isLoadMore) {
      return;
    }

    this.setState({
      isLoadMore: true
    }, () => {
      this.getData();
    });
  };

  renderMessage = (message: IChatDialogMessageReponse): React.ReactNode => {
    const {dialog, t} = this.props;
    const {members, currentUserId} = this.state;
    const isCurrentUserMessage = currentUserId === message.from_id;
    const senderName = isCurrentUserMessage
      ? t!('you')
      : dialog.type === 1
        ? dialog.title
        : members.filter(item => item.user_id === message.from_id)[0].username;

    return (
      <Message
        dialogReceiver={dialog.to_user_id}
        message={message}
        isCurrentUserMessage={isCurrentUserMessage}
        senderName={senderName} />
    );
  };

  render() {
    const {dialog, onlineUsers, availableUsers, t} = this.props;
    const {
      messages,
      message,
      showProfile,
      showAttachBlock,
      error,
      showChatGroupForm,
      showChatGroupAddForm,
      members,
      attachments,
      typing,
      chatGroupAddId,
      showEmoji,
      sending,
      hasMore,
      searchText,
      settings,
      hasPermission
    } = this.state;

    const isOnline: boolean = onlineUsers.indexOf(dialog.to_user_id) > -1;

    if (showChatGroupForm) {
      return (
        <ChatGroupForm
          onDialogCreated={this.onGroupDialogCreated}
          closeCreateGroup={this.closeCreateGroup}
          chatGroupAddId={chatGroupAddId}
          onlineUsers={onlineUsers}
          availableUsers={availableUsers} />
      );
    }

    if (showChatGroupAddForm) {
      return (
        <GroupAddForm
          members={members}
          dialog={dialog}
          onAddedToGroup={this.onAddedToGroup}
          closeGroupAddForm={this.closeCreateAddToGroup}
          onlineUsers={onlineUsers}
          availableUsers={availableUsers} />
      );
    }

    if (showProfile) {
      return (
        <ChatProfile
          onClickAddToGroup={this.onClickAddToGroupProfile}
          members={members}
          availableUsers={availableUsers}
          settings={settings}
          isOnline={isOnline}
          dialog={dialog}
          hasPermission={hasPermission}
          searchText={searchText}
          onSearch={this.onSearch}
          closeProfile={this.hideProfile} />
      );
    }

    const messageGroups = this.groupMessages(messages);
    const canAttachFiles = settings.attach_files || settings.attach_photo_video;

    return (
      <React.Fragment>
        <div
          className="chat-dialog-blk"
          style={{
            display: 'block'
          }}>
          <div className="head-chat-blk">
            <div className="photo-blk">
              {/*<div className={classNames('image', {*/}
              {/*  online: isOnline*/}
              {/*})} style={{backgroundImage: 'url(images/avatar_img_1.png)'}}>*/}
              {/*</div>*/}
              <div className={classNames('image', {
                "online": isOnline,
                "no-avatar": !dialog.photo
              })} style={{
                backgroundColor: getRandomColor(dialog.title),
                backgroundImage: getChatAvatar(dialog.photo) || ''
              }}>
                {!dialog.photo && getShortName(dialog.title)}
              </div>
            </div>
            <div className="name">
              {dialog.title} <i className="chevron" />
              <div className="chat-menu">
                <a
                  className="link open-info-chat-link"
                  onClick={this.showProfile}>
                  {t!('view_profile_chat')}
                </a>
                {dialog.type === 1 && (
                  <a
                    className="link create-group-link"
                    onClick={this.onClickCreateGroup}>
                    {t!('create_group')}
                  </a>
                )}
                {dialog.type === 2 && (
                  <a
                    className="link create-group-link"
                    onClick={this.onClickAddToGroup}>
                    {t!('add_to_group')}
                  </a>
                )}
                {/*<a*/}
                {/*  className="link block"*/}
                {/*  onClick={this.onClickBlockUser}>*/}
                {/*  {t!('block')}*/}
                {/*</a>*/}
              </div>
            </div>
            <a className="close-dialog-link" onClick={this.onClickCloseDialog} />
          </div>
          <div
            ref={this.chatRef}
            className="messages-blk"
            style={{
              height: 460 - 54
            }}>
            <InfiniteScroll
              isReverse={true}
              pageStart={0}
              hasMore={hasMore}
              loadMore={this.onLoadMore}
              useWindow={false}
              loader={<div className="loader" key={0} />}
            >
              <React.Fragment>
                {error && (
                  <div
                    className="chat-dialog-blk"
                    style={{
                      display: 'block'
                    }}>
                    <div className="head-chat-blk">
                      <div className="name">
                        {t!('error')}
                      </div>
                    </div>
                    <div
                      className="messages-blk"
                      style={{
                        height: 460 - 54
                      }}>
                      <p style={{margin: 0}}>{t!('cant_load_data')}</p>
                      <a href="#" onClick={this.onClickRetry}><small>{t!('repeat')}</small></a>
                    </div>
                  </div>
                )}
                {Object.keys(messageGroups).map((time) => (
                  <React.Fragment key={time}>
                    <div className="day">{time === format(new Date(), 'dd.MM.yyyy') ? t!('today') : time}</div>
                    {messageGroups[time].map((message, messageIndex: number) => (
                      <React.Fragment key={messageIndex.toString()}>
                        {this.renderMessage(message)}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
                {!!typing && (
                  <div className="status-typing">{typing} {t!('typing')}...</div>
                )}
                {this.state.errorTimeOut && (
                  // @ts-ignore
                  <div className="status-typing">{typing} {t!('block_send_message_seconds', {
                    seconds: settings.timeout_of_sending
                  })}...</div>
                )}
              </React.Fragment>
            </InfiniteScroll>
          </div>
          {(canAttachFiles || settings.send_message || hasPermission) && (
            <div className="form-chat-blk">
              {showEmoji && (
                <EmojiContainer onBlur={() => this.setState({showEmoji: false})}>
                  <Picker onEmojiClick={this.onClickEmoji} disableSearchBar={true} />
                </EmojiContainer>
              )}
              {attachments.length > 0 && (
                <div className="attaches-preview">
                  <div className="list-attaches">
                    {attachments.map((attachment, attachmentIndex) => (
                      <div className={classNames('img', {
                        'file': [2, 3].indexOf(this.state.attachType) === -1
                      })} style={{
                        backgroundColor: '#08B2B1',
                        backgroundImage: [2, 3].indexOf(this.state.attachType) === -1 ? 'url(../images/file_icon_white.svg)' : this.getAttachmentURLObject(attachment)
                      }}
                           key={attachmentIndex.toString()}>
                        <a href="#" className="delete-attach" onClick={this.onClickDeleteAttachment(attachmentIndex)} />
                      </div>
                    ))}
                    <a href="#" className="delete-all" onClick={this.onClickDeleteAttachments} />
                  </div>
                </div>
              )}
              <div className="form-blk">
                {(settings.attach_files || settings.attach_photo_video) && (
                  <a className="attach-link" onClick={this.showAttachBlock} />
                )}
                {settings.send_message && (
                  <div className="text-input-blk">
                  <textarea
                    rows={1}
                    ref={this.emojiPicker}
                    onKeyPress={this.onInputKeyPress}
                    placeholder={t!('enter_the_message')}
                    value={message}
                    onChange={this.onChangeMessage} />
                    <a href="#" className="smile-link" onClick={this.onClickShowEmoji} />
                    <a
                      href="#"
                      className="send-link"
                      onClick={this.onClickSendMessage} />
                  </div>
                )}
              </div>
              {(canAttachFiles || hasPermission) && (
                <div className="attach-form-blk" style={{
                  display: showAttachBlock ? 'block' : 'none'
                }}>
                  <div className="title-block">
                    <div className="name bebas">{t!('add')}</div>
                    <a className="close-attach" onClick={this.hideAttachBlock} />
                  </div>
                  {settings.attach_photo_video && (
                    <React.Fragment>
                      <a className="attach-form-link i1" onClick={this.setAttachType(2)}>{t!('photos')}</a>
                      <a className="attach-form-link i2" onClick={this.setAttachType(3)}>{t!('video')}</a>
                    </React.Fragment>
                  )}
                  {settings.attach_files && (
                    <a className="attach-form-link i3" onClick={this.setAttachType(1)}>{t!('file')}</a>
                  )}
                  <input
                    type="file"
                    multiple={true}
                    style={{display: 'none'}}
                    accept={this.getFileAccepts()}
                    ref={this.attachInput}
                    onChange={this.onFilesSelect} />
                </div>
              )}
              {sending && (
                <div className="send-loader">
                  <div className="preloader-image">
                    <object data="images/loader.svg" type="image/svg+xml" id="loader" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Chat);
