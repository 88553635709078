import classNames from 'classnames';
import React from 'react';
import cookies from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import SocketIOClient from 'socket.io-client';
import { SOCKET_URL } from '../../api';
import { getChatAvatar, getRandomColor, getShortName, getUserThumb, USER_ROLES } from '../../helpers';
import { IAvatar } from '../../interfaces';
import { IStore } from '../../store';
import {
  createPersonalDialog,
  deleteDialog,
  getAvailableUsers,
  getChatDialogs,
  IChatDialogMessageReponse
} from './api';
import Chat from './Chat';
import NewDialog from './NewDialog';
import { ASetDialogs, IDialog } from './reducer';

export const CONSTANTS = {
  AUTH: 'auth',
  AUTH_SUCCESS: 'auth success',
  SEND_MESSAGE: 'send message',
  GET_MESSAGE: 'get message',
  TYPING: 'typing',
  USER_ONLINE: 'user online',
  USER_OFFLINE: 'user disconnected',
  SETTINGS_UPDATED: 'chat settings updated',
  ADD_TO_GROUP: 'add member to group',
  DELETE_FROM_GROUP: 'remove member of group',
  ON_USER_REMOVED: 'user removed',
  ON_USER_ADDED: 'users added to group',
  ON_NEW_DIALOG: 'new dialog',
  ON_UPDATE_AVATAR: 'avatar updated on chat',
  JOIN_CHAT: 'join chat',
};

export const socket = SocketIOClient(SOCKET_URL);

export interface IChatUserInfo {
  username: string;
  user_id: number;
  avatar?: IAvatar;
}

interface IChatUser {
  api_token: string;
  avatar: IAvatar;
  created_at: string;
  last_seen: string;
  members: IChatUserInfo[]
  mentors: IChatUserInfo[]
  role: {
    id: number,
    title: string
  }
  updated_at: string;
  user_id: number;
  username: string;
}

interface IChatAuthResponse {
  msg: string;
  online: {
    username: string;
    user_id: number;
  }[],
  user: IChatUser;
}

interface IChatConnectProps {
  dialogs: IDialog[],
  setDialogs: (dialogs: IDialog[]) => void,
  t?: (key: string) => string
}

interface IChatConnectState {
  onlineUsers: number[];
  user: IChatUser | null;
  dialogId: string;
  showDialog: boolean;
  availableUsers: IChatUserInfo[];
}

class ChatConnect extends React.Component<IChatConnectProps> {
  state: IChatConnectState = {
    onlineUsers: [],
    user: null,
    dialogId: '',
    showDialog: false,
    availableUsers: []
  };

  componentDidMount() {
    if (socket.disconnected) {
      socket.connect();
    }

    socket.emit(CONSTANTS.AUTH, {
      api_token: cookies.load('token')
    });

    socket.on(CONSTANTS.AUTH_SUCCESS, (response: IChatAuthResponse) => {
      if ('Notification' in window) {
        if (['denied', 'default'].indexOf(Notification.permission) > -1) {
          (async () => {
            await Notification.requestPermission();
          })();
        }
      }

      this.setState({
        onlineUsers: response.online || [],
        user: response.user
      }, () => {
        this.getData();

        socket.on(CONSTANTS.GET_MESSAGE, (response: IChatDialogMessageReponse) => {
          const notification = new Notification(this.props.t!('new_message'), {
            body: response.message
          });

          notification.onclick = () => {
          };
        });

        socket.on(CONSTANTS.USER_ONLINE, (response: number[]) => {
          this.setState({
            onlineUsers: response
          });
        });

        socket.on(CONSTANTS.USER_OFFLINE, (response: IChatUser) => {
          this.setState({
            onlineUsers: [...this.state.onlineUsers].filter(onlineUserId => onlineUserId !== response.user_id)
          });
        });

        socket.on(CONSTANTS.ON_USER_REMOVED, ({userId, dialog}: any) => {
          if (userId === this.state.user?.user_id) {
            this.props.setDialogs(this.props.dialogs.filter(item => item._id !== dialog._id));
          }
        });

        socket.on(CONSTANTS.ON_USER_ADDED, ({userId, dialog}: any) => {
          if (userId === this.state.user?.user_id) {
            const hasDialog = this.props.dialogs.filter(item => item._id === dialog._id)[0];

            if (!hasDialog) {
              this.props.setDialogs([...this.props.dialogs, {...dialog}]);
            }
          }
        });

        socket.on(CONSTANTS.ON_NEW_DIALOG, ({userId, dialog}: any) => {
          if (userId === this.state.user?.user_id) {
            const hasDialog = this.props.dialogs.filter(item => item._id === dialog._id)[0];

            if (!hasDialog) {
              this.props.setDialogs([...this.props.dialogs, {...dialog}]);
            }
          }
        });

        // socket.on(CONSTANTS.ON_UPDATE_AVATAR, (chat: any) => {
        //   console.log(chat);
        //   if (this.props.dialogs.filter(item => item.chat_id === chat.chat_id).length > 0) {
        //     this.props.setDialogs(this.props.dialogs.map(item => {
        //       if (item.chat_id !== chat.chat_id) {
        //         return item;
        //       }
        //
        //       return {
        //         ...item,
        //         photo: chat.photo
        //       }
        //     }));
        //   }
        // });
      });
    });
  }

  componentWillUnmount() {
    this.setState({
      onlineUsers: [],
      user: null,
      dialogId: '',
      showDialog: false,
      availableUsers: []
    }, () => {
      this.props.setDialogs([]);
      socket.disconnect();
    });
  }

  getData = async (callback?: () => void) => {
    try {
      const dialogs = await getChatDialogs();
      const availableUsers = this.state.user!.role.id !== USER_ROLES.member.id ? await getAvailableUsers() : [];

      this.setState({
        availableUsers
      }, () => {
        this.props.setDialogs(dialogs);
      });
    } catch (e) {
    }
  };

  onClickNewDialog = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    this.setState({
      dialogId: '',
      showDialog: true
    });
  };

  onClickDialog = (dialogId: string) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    if (dialogId === this.state.dialogId) {
      return;
    }

    this.setState({
      dialogId: '',
      showDialog: false
    }, () => {
      this.setState({
        dialogId,
        showDialog: true
      });
    });
  };

  onClickDeleteDialog = (dialogId: string) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    const confirmed = window.confirm(this.props.t!('delete_chat_confirm_message'));

    if (!confirmed) {
      return;
    }

    this.props.setDialogs(this.props.dialogs.filter(dialog => dialog._id !== dialogId));

    deleteDialog(dialogId)
      .then(() => {
      })
      .catch(() => {
      });

    if (dialogId === this.state.dialogId) {
      this.closeDialog();
    }
  };

  createPersonalDialog = (userId: number): void => {
    this.setState({
      dialogId: '',
      showDialog: false
    }, () => {
      const {dialogs} = this.props;
      const filteredDialog = dialogs.filter(dialog => dialog.to_user_id === userId)[0];

      if (filteredDialog) {
        return this.setState({
          dialogId: filteredDialog._id,
          showDialog: true
        });
      }

      createPersonalDialog(userId)
        .then(response => {

          if (dialogs.length === 0 || dialogs.map(dialog => dialog._id).indexOf(response._id) === -1) {
            dialogs.push({...response});
          }

          this.setState({
            dialogId: response._id,
            showDialog: true
          }, () => {
            socket.emit(CONSTANTS.JOIN_CHAT, {
              chat_id: response.chat_id
            });

            this.props.setDialogs(dialogs);
          });
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
        });
    });
  };

  closeDialog = (): void => {
    this.setState({
      dialogId: '',
      showDialog: false
    });
  };

  onCreateGroupDialog = (dialog: IDialog): void => {
    this.setState({
      dialogId: '',
      showDialog: false
    }, () => {
      this.props.setDialogs([...this.props.dialogs, dialog]);

      this.setState({
        dialogId: dialog._id,
        showDialog: true
      });
    });
  };

  render() {
    const {dialogs} = this.props;
    const {onlineUsers, user, dialogId, showDialog, availableUsers} = this.state;

    if (!user) {
      return null;
    }

    return (
      <div className="chat-block">
        {/* {
          dialogs.length > 0 && */}
          <div className="in-blk">
          <div className="list-open-chats">
            {dialogs.map((dialog) => (
              <div
                key={dialog._id}
                className="open-elm">
                {/*<a*/}
                {/*  onClick={this.onClickDialog(dialog._id)}*/}
                {/*  className="open-chat-link"*/}
                {/*  style={{*/}
                {/*    backgroundImage: getUserThumb(dialog.photo, false) || 'url(images/test_program_img_1.jpg)'*/}
                {/*  }} />*/}
                <a
                  onClick={this.onClickDialog(dialog._id)}
                  className={classNames("open-chat-link", {
                    "no-avatar": !dialog.photo
                  })} data-id="2"
                  style={{
                    backgroundImage: getChatAvatar(dialog.photo, true) || '',
                    backgroundColor: getRandomColor(dialog.title)
                  }}>
                  {!dialog.photo && getShortName(dialog.title)}
                </a>
                {user.role.id !== USER_ROLES.member.id && (
                  <a
                    className="close-chat-link"
                    onClick={this.onClickDeleteDialog(dialog._id)} />
                )}
                {onlineUsers.indexOf(dialog.to_user_id) > -1 && <span className="online" />}
              </div>
            ))}
            {user.role.id !== USER_ROLES.member.id && (
              <a
                href="#"
                className="create-chat"
                onClick={this.onClickNewDialog} />
            )}
          </div>

          {showDialog && (
            <React.Fragment>
              {!dialogId && (
                <NewDialog
                  availableUsers={availableUsers}
                  onlineUsers={onlineUsers}
                  closeDialog={this.closeDialog}
                  createDialog={this.createPersonalDialog}
                />
              )}
              {!!dialogId && (
                <Chat
                  onCreateGroupDialog={this.onCreateGroupDialog}
                  availableUsers={availableUsers}
                  onlineUsers={onlineUsers}
                  closeDialog={this.closeDialog}
                  dialog={dialogs.filter(dialog => dialog._id === dialogId)[0]} />
              )}
            </React.Fragment>
          )}
        </div>
        {/* } */}

      </div>
    );
  }
}

const mapStateToProps = ({dialogs}: IStore) => ({
  dialogs
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setDialogs: ASetDialogs
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChatConnect));
