import qs from 'qs';
import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import LoadablePage from '../../components/LoadablePage';
import { IStore } from '../../store';
import { getSearch } from './api';

const getParams = () => {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const returnParams: any = {
    page: params.page || 1
  };

  if (!!params.q) {
    returnParams.q = params.q;
  }

  window.history.replaceState('', '', `${window.location.origin}${window.location.pathname}${qs.stringify(returnParams, {
    addQueryPrefix: true
  })}`);

  return returnParams;
};

const PageSMI: React.FC = (props) => {
  const locale = useSelector((state: IStore) => state.locale);
  const params = getParams();

  const LoadableMap = Loadable.Map({

    loader: {
      page: () => import(`./page`),
      result: () => getSearch(params)
    },
    loading: LoadablePage,
    render: (loaded, loadedProps): React.ReactNode => {
      const Page: any = loaded.page.default;
      const result = loaded.result;

      return (
        <Page
          {...loadedProps}
          locale={locale}
          result={result.data}/>
      );
    }
  });

  return <LoadableMap {...props} />;
};

export default PageSMI;
